const initialState = {
    ticketStatusLogList: []  
  }
  
  const TicketStatusLogReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_TICKET_STATUS_LOG_SUCCESS": {             
        return {
          ...state,
          getTicketStatusLogSuccess: true,
          getTicketStatusLogList: action.payload.data,
        };
      }
      case "GET_TICKET_STATUS_LOG_FAILURE": {
        return {
          ...state,
          getTicketStatusLogFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TICKET_STATUS_LOG": {
        return {
          ...state,
          getTicketStatusLogSuccess: false,
          getTicketStatusLogFailure: false,
          getTicketStatusLogList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_TICKET_STATUS_LOG_SUCCESS": {             
        return {
          ...state,
          createTicketStatusLogSuccess: true,
          createTicketStatusLogData: action.payload.data,
        };
      }
      case "CREATE_TICKET_STATUS_LOG_FAILURE": {
        return {
          ...state,
          createTicketStatusLogFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_TICKET_STATUS_LOG": {
        return {
          ...state,
          createTicketStatusLogSuccess: false,
          createTicketStatusLogFailure: false,
          createTicketStatusLogData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_TICKET_STATUS_LOG_SUCCESS": {             
        return {
          ...state,
          updateTicketStatusLogSuccess: true,
          updateTicketStatusLogData: action.payload.data,
        };
      }
      case "UPDATE_TICKET_STATUS_LOG_FAILURE": {
        return {
          ...state,
          updateTicketStatusLogFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_TICKET_STATUS_LOG": {
        return {
          ...state,
          updateTicketStatusLogSuccess: false,
          updateTicketStatusLogFailure: false,
          updateTicketStatusLogData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default TicketStatusLogReducer
  
  