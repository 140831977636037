import { lazy } from "react";

const ClosedCaseList = lazy(() => import("./closedCaseList"));

const closedCaseRoutes = [
  {
    path: "/closed-case",
    element: <ClosedCaseList />,
  }
];

export default closedCaseRoutes;
