const initialState = {
    companyBankSettingsList: []  
  }
  
  const CompanyBankSettingsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_COMPANY_BANK_SETTINGS_SUCCESS": {             
        return {
          ...state,
          getCompanyBankSettingsSuccess: true,
          getCompanyBankSettingsList: action.payload.data,
        };
      }
      case "GET_COMPANY_BANK_SETTINGS_FAILURE": {
        return {
          ...state,
          getCompanyBankSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_COMPANY_BANK_SETTINGS": {
        return {
          ...state,
          getCompanyBankSettingsSuccess: false,
          getCompanyBankSettingsFailure: false,
          getCompanyBankSettingsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_COMPANY_BANK_SETTINGS_SUCCESS": {             
        return {
          ...state,
          createCompanyBankSettingsSuccess: true,
          createCompanyBankSettingsData: action.payload.data,
        };
      }
      case "CREATE_COMPANY_BANK_SETTINGS_FAILURE": {
        return {
          ...state,
          createCompanyBankSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_COMPANY_BANK_SETTINGS": {
        return {
          ...state,
          createCompanyBankSettingsSuccess: false,
          createCompanyBankSettingsFailure: false,
          createCompanyBankSettingsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_COMPANY_BANK_SETTINGS_SUCCESS": {             
        return {
          ...state,
          updateCompanyBankSettingsSuccess: true,
          updateCompanyBankSettingsData: action.payload.data,
        };
      }
      case "UPDATE_COMPANY_BANK_SETTINGS_FAILURE": {
        return {
          ...state,
          updateCompanyBankSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_COMPANY_BANK_SETTINGS": {
        return {
          ...state,
          updateCompanyBankSettingsSuccess: false,
          updateCompanyBankSettingsFailure: false,
          updateCompanyBankSettingsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CompanyBankSettingsReducer
  
  