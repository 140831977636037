import { lazy } from "react";

const ZoneList = lazy(() => import("./zoneList"));

const zoneRoutes = [
  {
    path: "/zone-post",
    element: <ZoneList />,
  }
];

export default zoneRoutes;
