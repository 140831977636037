import { lazy } from "react";

const PurposeList = lazy(() => import("./purposeList"));

const purposeRoutes = [
  {
    path: "/purpose",
    element: <PurposeList />,
  }
];

export default purposeRoutes;
