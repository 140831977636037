const initialState = {
    documentTypeList: []  
  }
  
  const DocumentTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DOCUMENT_TYPE_SUCCESS": {             
        return {
          ...state,
          getDocumentTypeSuccess: true,
          getDocumentTypeList: action.payload.data,
        };
      }
      case "GET_DOCUMENT_TYPE_FAILURE": {
        return {
          ...state,
          getDocumentTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DOCUMENT_TYPE": {
        return {
          ...state,
          getDocumentTypeSuccess: false,
          getDocumentTypeFailure: false,
          getDocumentTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_DOCUMENT_TYPE_SUCCESS": {             
        return {
          ...state,
          createDocumentTypeSuccess: true,
          createDocumentTypeData: action.payload.data,
        };
      }
      case "CREATE_DOCUMENT_TYPE_FAILURE": {
        return {
          ...state,
          createDocumentTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_DOCUMENT_TYPE": {
        return {
          ...state,
          createDocumentTypeSuccess: false,
          createDocumentTypeFailure: false,
          createDocumentTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_DOCUMENT_TYPE_SUCCESS": {             
        return {
          ...state,
          updateDocumentTypeSuccess: true,
          updateDocumentTypeData: action.payload.data,
        };
      }
      case "UPDATE_DOCUMENT_TYPE_FAILURE": {
        return {
          ...state,
          updateDocumentTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_DOCUMENT_TYPE": {
        return {
          ...state,
          updateDocumentTypeSuccess: false,
          updateDocumentTypeFailure: false,
          updateDocumentTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DocumentTypeReducer
  
  