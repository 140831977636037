const initialState = {
    valuationTypeList: []  
  }
  
  const ValuationTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_VALUATION_TYPE_SUCCESS": {             
        return {
          ...state,
          getValuationTypeSuccess: true,
          getValuationTypeList: action.payload.data,
        };
      }
      case "GET_VALUATION_TYPE_FAILURE": {
        return {
          ...state,
          getValuationTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_VALUATION_TYPE": {
        return {
          ...state,
          getValuationTypeSuccess: false,
          getValuationTypeFailure: false,
          getValuationTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_VALUATION_TYPE_SUCCESS": {             
        return {
          ...state,
          createValuationTypeSuccess: true,
          createValuationTypeData: action.payload.data,
        };
      }
      case "CREATE_VALUATION_TYPE_FAILURE": {
        return {
          ...state,
          createValuationTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_VALUATION_TYPE": {
        return {
          ...state,
          createValuationTypeSuccess: false,
          createValuationTypeFailure: false,
          createValuationTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_VALUATION_TYPE_SUCCESS": {             
        return {
          ...state,
          updateValuationTypeSuccess: true,
          updateValuationTypeData: action.payload.data,
        };
      }
      case "UPDATE_VALUATION_TYPE_FAILURE": {
        return {
          ...state,
          updateValuationTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_VALUATION_TYPE": {
        return {
          ...state,
          updateValuationTypeSuccess: false,
          updateValuationTypeFailure: false,
          updateValuationTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ValuationTypeReducer
  
  