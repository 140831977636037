const initialState = {
    ticketDetailsList: []  
  }
  
  const TicketDetailsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_TICKET_DETAILS_SUCCESS": {             
        return {
          ...state,
          getTicketDetailsSuccess: true,
          getTicketDetailsList: action.payload.data,
        };
      }
      case "GET_TICKET_DETAILS_FAILURE": {
        return {
          ...state,
          getTicketDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TICKET_DETAILS": {
        return {
          ...state,
          getTicketDetailsSuccess: false,
          getTicketDetailsFailure: false,
          getTicketDetailsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_TICKET_DETAILS_SUCCESS": {             
        return {
          ...state,
          createTicketDetailsSuccess: true,
          createTicketDetailsData: action.payload.data,
        };
      }
      case "CREATE_TICKET_DETAILS_FAILURE": {
        return {
          ...state,
          createTicketDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_TICKET_DETAILS": {
        return {
          ...state,
          createTicketDetailsSuccess: false,
          createTicketDetailsFailure: false,
          createTicketDetailsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_TICKET_DETAILS_SUCCESS": {             
        return {
          ...state,
          updateTicketDetailsSuccess: true,
          updateTicketDetailsData: action.payload.data,
        };
      }
      case "UPDATE_TICKET_DETAILS_FAILURE": {
        return {
          ...state,
          updateTicketDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_TICKET_DETAILS": {
        return {
          ...state,
          updateTicketDetailsSuccess: false,
          updateTicketDetailsFailure: false,
          updateTicketDetailsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default TicketDetailsReducer
  
  