import { lazy } from "react";

const AbortedCaseList = lazy(() => import("./abortedCaseList"));

const abortedCaseRoutes = [
  {
    path: "/aborted-case",
    element: <AbortedCaseList />,
  }
];

export default abortedCaseRoutes;
