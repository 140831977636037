const initialState = {
    ceilingList: []  
  }
  
  const CeilingReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CEILING_SUCCESS": {             
        return {
          ...state,
          getCeilingSuccess: true,
          getCeilingList: action.payload.data,
        };
      }
      case "GET_CEILING_FAILURE": {
        return {
          ...state,
          getCeilingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CEILING": {
        return {
          ...state,
          getCeilingSuccess: false,
          getCeilingFailure: false,
          getCeilingList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CEILING_SUCCESS": {             
        return {
          ...state,
          createCeilingSuccess: true,
          createCeilingData: action.payload.data,
        };
      }
      case "CREATE_CEILING_FAILURE": {
        return {
          ...state,
          createCeilingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CEILING": {
        return {
          ...state,
          createCeilingSuccess: false,
          createCeilingFailure: false,
          createCeilingData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_CEILING_SUCCESS": {             
        return {
          ...state,
          updateCeilingSuccess: true,
          updateCeilingData: action.payload.data,
        };
      }
      case "UPDATE_CEILING_FAILURE": {
        return {
          ...state,
          updateCeilingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CEILING": {
        return {
          ...state,
          updateCeilingSuccess: false,
          updateCeilingFailure: false,
          updateCeilingData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CeilingReducer
  
  