import { lazy } from "react";

const LightingList = lazy(() => import("./lightingList"));

const lightingRoutes = [
  {
    path: "/lighting",
    element: <LightingList />,
  }
];

export default lightingRoutes;
