const initialState = {
    viewList: []  
  }
  
  const ViewReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_VIEW_SUCCESS": {             
        return {
          ...state,
          getViewSuccess: true,
          getViewList: action.payload.data,
        };
      }
      case "GET_VIEW_FAILURE": {
        return {
          ...state,
          getViewFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_VIEW": {
        return {
          ...state,
          getViewSuccess: false,
          getViewFailure: false,
          getViewList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_VIEW_SUCCESS": {             
        return {
          ...state,
          createViewSuccess: true,
          createViewData: action.payload.data,
        };
      }
      case "CREATE_VIEW_FAILURE": {
        return {
          ...state,
          createViewFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_VIEW": {
        return {
          ...state,
          createViewSuccess: false,
          createViewFailure: false,
          createViewData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_VIEW_SUCCESS": {             
        return {
          ...state,
          updateViewSuccess: true,
          updateViewData: action.payload.data,
        };
      }
      case "UPDATE_VIEW_FAILURE": {
        return {
          ...state,
          updateViewFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_VIEW": {
        return {
          ...state,
          updateViewSuccess: false,
          updateViewFailure: false,
          updateViewData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ViewReducer
  
  