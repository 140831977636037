const initialState = {
    subReportTypeList: []  
  }
  
  const SubReportTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SUB_REPORT_TYPE_SUCCESS": {             
        return {
          ...state,
          getSubReportTypeSuccess: true,
          getSubReportTypeList: action.payload.data,
        };
      }
      case "GET_SUB_REPORT_TYPE_FAILURE": {
        return {
          ...state,
          getSubReportTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SUB_REPORT_TYPE": {
        return {
          ...state,
          getSubReportTypeSuccess: false,
          getSubReportTypeFailure: false,
          getSubReportTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SUB_REPORT_TYPE_SUCCESS": {             
        return {
          ...state,
          createSubReportTypeSuccess: true,
          createSubReportTypeData: action.payload.data,
        };
      }
      case "CREATE_SUB_REPORT_TYPE_FAILURE": {
        return {
          ...state,
          createSubReportTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SUB_REPORT_TYPE": {
        return {
          ...state,
          createSubReportTypeSuccess: false,
          createSubReportTypeFailure: false,
          createSubReportTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SUB_REPORT_TYPE_SUCCESS": {             
        return {
          ...state,
          updateSubReportTypeSuccess: true,
          updateSubReportTypeData: action.payload.data,
        };
      }
      case "UPDATE_SUB_REPORT_TYPE_FAILURE": {
        return {
          ...state,
          updateSubReportTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SUB_REPORT_TYPE": {
        return {
          ...state,
          updateSubReportTypeSuccess: false,
          updateSubReportTypeFailure: false,
          updateSubReportTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SubReportTypeReducer
  
  