import { lazy } from "react";

const IndicativeValuationList = lazy(() => import("./indicativeValuationList"));

const indicativeValuationRoutes = [
  {
    path: "/indicative-valuation",
    element: <IndicativeValuationList />,
  }
];

export default indicativeValuationRoutes;
