import { differenceInSeconds } from 'date-fns'
import moment from 'moment'
import { currency } from './constant'
//import imageCompression from 'browser-image-compression';
import _ from 'lodash';
import { todayDate } from './app-functions';
// import { SnackbarProvider, useSnackbar } from 'notistack'
// const { enqueueSnackbar, closeSnackbar } = useSnackbar()

export function percentage(num, per) {
    return (num && per) ? (num / 100) * per : num;
}

export function roundValue(data) {
    return data ? parseFloat(data)?.toFixed(2) : data;
}

export function getArr2UniqueStr(data, key) {
    const val = (data ? data : []).map((item) => item[key]);
    let selectedStr = val.toString();
    return selectedStr;
}

export const convertHexToRGB = (hex) => {
    // check if it's a rgba
    if (hex.match('rgba')) {
        let triplet = hex.slice(5).split(',').slice(0, -1).join(',')
        return triplet
    }

    let c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')

        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')
    }
}
export function validateEmptyString(str) {
    let rtnStr = "";
    if (str === "undefined" || str === undefined || str === null || str === "" || str === "null") {
        rtnStr = "";
    } else {
        rtnStr = str;
    }
    return rtnStr;
}

export function check0rEmpty(str) {
    let rtnStr = "";
    if (str === "undefined" || str === undefined || str === null || str === "" || str === "null" || str === 0 || str === "0") {
        rtnStr = "";
    } else {
        rtnStr = str;
    }
    return rtnStr;
}

export function compressImage_1(source_img_obj, quality, maxWidth, output_format) {
    var mime_type = "image/jpeg";
    if (typeof output_format !== "undefined" && output_format == "png") {
        mime_type = "image/png";
    }

    maxWidth = maxWidth || 1000;
    var natW = source_img_obj.naturalWidth;
    var natH = source_img_obj.naturalHeight;
    var ratio = natH / natW;
    if (natW > maxWidth) {
        natW = maxWidth;
        natH = ratio * maxWidth;
    }

    var cvs = document.createElement('canvas');
    cvs.width = natW;
    cvs.height = natH;
    // alert(source_img_obj)
    var ctx = cvs.getContext("2d").drawImage(source_img_obj, 0, 0, natW, natH);
    // alert(ctx)
    var newImageData = cvs.toDataURL(mime_type, quality / 100);

    var result_image_obj = new Image();
    result_image_obj.src = newImageData;

    return result_image_obj;
}

export async function compressImage_2(file) {
    let dddd = "";
    const MAX_WIDTH = 320;
    const MAX_HEIGHT = 180;
    const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.7;
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    img.onerror = function () {
        URL.revokeObjectURL(this.src);
        // Handle the failure properly
        console.log("Cannot load image");
    };
    img.onload = function () {
        URL.revokeObjectURL(this.src);
        const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        canvas.toBlob(
            (blob) => {
                // alert(blob)
                dddd = blob;
                // return blob;
                // Handle the compressed image. es. upload or save in local state
                // displayInfo('Original file', file);
                // displayInfo('Compressed file', blob);
            },
            MIME_TYPE,
            QUALITY
        );
        // document.getElementById("root").append(canvas);
    };

    // alert(dddd)
}

/* export async function compressImage(file, maxWidthOrHeight = 100, maxSize = 0.2) {

    const imageFile = file;
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
        maxSizeMB: maxSize,
        maxWidthOrHeight: maxWidthOrHeight,
        useWebWorker: true
    }
    try {
        const compressedFile = await imageCompression(imageFile, options);
        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

        return compressedFile;
        // await uploadToServer(compressedFile); // write your own logic
    } catch (error) {
        console.log(error);
    }

} */

export function calculateSize(img, maxWidth, maxHeight) {
    let width = img.width;
    let height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
    }
    return [width, height];
}

// Utility functions for demo purpose

export function displayInfo(label, file) {
    const p = document.createElement('p');
    p.innerText = `${label} - ${readableBytes(file.size)}`;
    document.getElementById('root').append(p);
}

export function readableBytes(bytes) {
    const i = Math.floor(Math.log(bytes) / Math.log(1024)),
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

export function debounce(func, wait, immediate) {
    var timeout
    return function () {
        var context = this,
            args = arguments
        clearTimeout(timeout)
        timeout = setTimeout(function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }, wait)
        if (immediate && !timeout) func.apply(context, args)
    }
}

export function isMobile() {
    if (window) {
        return window.matchMedia(`(max-width: 767px)`).matches
    }
    return false
}

export function isMdScreen() {
    if (window) {
        return window.matchMedia(`(max-width: 1199px)`).matches
    }
    return false
}

function currentYPosition(elm) {
    if (!window && !elm) {
        return
    }
    if (elm) return elm.scrollTop
    // Firefox, Chrome, Opera, Safari
    if (window.pageYOffset) return window.pageYOffset
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop)
        return document.documentElement.scrollTop
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) return document.body.scrollTop
    return 0
}

function elmYPosition(elm) {
    var y = elm.offsetTop
    var node = elm
    while (node.offsetParent && node.offsetParent !== document.body) {
        node = node.offsetParent
        y += node.offsetTop
    }
    return y
}

export function scrollTo(scrollableElement, elmID) {
    var elm = document.getElementById(elmID)

    if (!elmID || !elm) {
        return
    }

    var startY = currentYPosition(scrollableElement)
    var stopY = elmYPosition(elm)

    var distance = stopY > startY ? stopY - startY : startY - stopY
    if (distance < 100) {
        scrollTo(0, stopY)
        return
    }
    var speed = Math.round(distance / 50)
    if (speed >= 20) speed = 20
    var step = Math.round(distance / 25)
    var leapY = stopY > startY ? startY + step : startY - step
    var timer = 0
    if (stopY > startY) {
        for (var i = startY; i < stopY; i += step) {
            setTimeout(
                (function (leapY) {
                    return () => {
                        scrollableElement.scrollTo(0, leapY)
                    }
                })(leapY),
                timer * speed
            )
            leapY += step
            if (leapY > stopY) leapY = stopY
            timer++
        }
        return
    }
    for (let i = startY; i > stopY; i -= step) {
        setTimeout(
            (function (leapY) {
                return () => {
                    scrollableElement.scrollTo(0, leapY)
                }
            })(leapY),
            timer * speed
        )
        leapY -= step
        if (leapY < stopY) leapY = stopY
        timer++
    }
    return false
}

export function getTimeDifference(date) {
    let difference = differenceInSeconds(new Date(), date)

    if (difference < 60) return `${Math.floor(difference)} sec`
    else if (difference < 3600) return `${Math.floor(difference / 60)} min`
    else if (difference < 86400) return `${Math.floor(difference / 3660)} h`
    else if (difference < 86400 * 30)
        return `${Math.floor(difference / 86400)} d`
    else if (difference < 86400 * 30 * 12)
        return `${Math.floor(difference / 86400 / 30)} mon`
    else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`
}

export function getDayDifference(date1Val, date2Val=todayDate("YYYY-MM-DD")) {
    const date1 = new Date(date1Val);
    const date2 = new Date(date2Val);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays
}

export function getYearDifference(date1Val, date2Val=todayDate("YYYY")) {
    const dateVal = new Date(date1Val);    
    const date1 = dateFormate(dateVal, "YYYY")    
    const date2 = date2Val;    
    const diffYear = date2 - date1;    
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffYear
}

export function parseDays (value) {
    const YEAR = 365, MONTH = 30, WEEK = 7;
    let year, months, week, days;
         
    year = value >= YEAR ? Math.floor(value / YEAR) : 0;
    return year
    value = year ? value - (year * YEAR) : value;
        
    months = value >= MONTH ? Math.floor((value % YEAR) / MONTH) : 0;
    value = months ? value - (months * MONTH) : value;
        
    week = value >= WEEK ? Math.floor((value % YEAR) / WEEK) : 0;
    value = week ? value - (week * WEEK) : value;
        
    days = value < WEEK ? Math.floor((value % YEAR) % WEEK) : 0;
        
    console.log(`years=${year},months=${months},weeks=${week},days=${days}`);     
}

export function convertSQData(val, type = "SQM-SQFT") {
    switch (type) {
        case 'SQM-SQFT':            
            return parseFloat((val || 0) * 10.76391041671)?.toFixed(2);
            // return parseInt((val || 0) * 10.76391041671)?.toFixed(2);
        case 'SQFT-SQM':
            return parseFloat((val || 0) * 0.092903)?.toFixed(2);
            // return parseInt((val || 0) * 0.092903)?.toFixed(2);
    }
    return val
}

export function numberWithCommas(x) {
    return x?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") || x;
}

export function generateRandomId() {
    let tempId = Math.random().toString()
    let uid = tempId.substr(2, tempId.length - 1)
    return uid
}

export function getQueryParam(prop) {
    var params = {}
    var search = decodeURIComponent(
        window.location.href.slice(window.location.href.indexOf('?') + 1)
    )
    var definitions = search.split('&')
    definitions.forEach(function (val, key) {
        var parts = val.split('=', 2)
        params[parts[0]] = parts[1]
    })
    return prop && prop in params ? params[prop] : params
}

export function classList(classes) {
    return Object.entries(classes)
        .filter((entry) => entry[1])
        .map((entry) => entry[0])
        .join(' ')
}

export const flat = (array) => {
    var result = []
    array.forEach(function (a) {
        result.push(a)
        if (Array.isArray(a.children)) {
            result = result.concat(flat(a.children))
        }
    })
    return result
}

export const ccyFormatWithCurrency = (num) => {
    return currency + (num ? `${parseFloat(num).toFixed(2)}` : "0.00");
}

export const ccyFormat = (num) => {
    return num ? `${parseFloat(num).toFixed(2)}` : "0.00";
}

export const currencyFormat = (num = 0, currency = "") => {
    return num ? currency + parseFloat(num).toFixed(2).replace(/./g, function (c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    }) : "0.0";
}

export const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
}

export const priceCalculation = (item, unitVal, unitDimension) => {

    let totalDimension = 0;
    if (item?.itemDetails) {
        // const unitVal = item.unit;            
        // const unitDimension = _.filter(unitList, function (o) { return o.unitId.toString() === unitVal?.toString(); });

        for (let k = 0; k < item?.itemDetails?.length; k++) {
            const itemDetails = item?.itemDetails[k]
            /* const sizeArr = itemDetails?.sizeOfTheGoods?.split("*");                
            const widthMM = sizeArr?.[0] || 0;
            const heightMM = sizeArr?.[1] || 0; */

            const widthMM = itemDetails?.itemWidth || 0;
            const lengthMM = itemDetails?.itemHeight || 0;
            const thicknessMM = itemDetails?.thickness || 0;
            const dimensionArr = [lengthMM, widthMM, thicknessMM]
            let unitDimensionArr = [];
            let dimensionStatus = [false, false, false];
            if (unitDimension?.length) {
                for (let l = 0; l < unitDimension[0].dimension; l++) {
                    unitDimensionArr.push(dimensionArr[l])
                    dimensionStatus[l] = true;
                }

                /* for(let m=unitDimension[0].dimension; m<dimensionStatus.length; m++){
                    if(m == 0){
                        item.itemDetails[k].itemWidth = "0";
                    } else if(m == 1){
                        item.itemDetails[k].itemHeight = "0";
                    } else if(m == 2){
                        item.itemDetails[k].thickness = "0";
                    }                    
                } */
                const convertedSize = MMCalculation(unitDimensionArr)
                totalDimension = totalDimension + convertedSize
            }

            item.itemDetails[k].dimensionStatus = dimensionStatus;
        }
    }
    const responseData = {
        "item": item,
        "totalDimension": totalDimension,
    }
    return responseData;
}

export const numberFloat = (data) => {

    let floatData = data ? parseFloat(data).toFixed(2) : 0.0;
    return floatData;
    // return Math.round(floatData);
    // return data ? parseFloat(data).toFixed(2).replace(/(\.0+|0+)$/, '') : 0.0;
}

// W * H * T
export const MMCalculation = (val = [0, 0, 0], formula = "mm") => {
    let data = 0;
    for (let i = 0; i < val.length; i++) {
        data = data == "0" ? 1 : data;
        data = (parseInt(val[i]) / 1000) * data;
    }
    return data;
}

export const generateSerial = () => {
    let now = Date.now().toString() // '1492341545873'
    return now;
    // pad with extra random digit
    now += now + Math.floor(Math.random() * 10)
    // format
    return [now.slice(0, 4), now.slice(4, 10), now.slice(10, 14)].join('-')
}

export const genHexString = (len = 8) => {
    let output = '';
    for (let i = 0; i < len; ++i) {
        output += (Math.floor(Math.random() * 16)).toString(16);
    }
    return output?.toUpperCase();
}

export const leftPad = (number, targetLength) => {
    var output = number + '';
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
}

export const dateFormate = (dateValue, formateType = 'YYYY-MM-DD HH:mm:ss') => {
    return dateValue ? moment(dateValue).format(formateType) : dateValue
}

export function currentDateTime(def = "YYYY-MM-DD HH:mm:ss") {
    let now = new Date();
    let dateString = moment(now).format(def);
    return dateString;
}

export const isDateValid = (dateValue) => {
    return !isNaN(new Date(dateValue))
}

export const snackbar = () => {
    // return enqueueSnackbar('I love snacks.', {variant: 'success'})
}

/**
 * @public
 * @static
 * Get filename and mine type */
export function getFileInfo(file: Object, mime: string = ''): { filename: string, mime: string } {
    const pos: number = String(file.name).lastIndexOf('.');

    if (mime === 'image/jpeg') {
        const filename = `${String(file.name)
            .substr(0, pos < 0 ? String(file.name).length : pos)}.jpg`;

        return {
            filename,
            mime: 'image/jpeg'
        };
    }

    return {
        filename: file.name,
        mime: file.type,
    };
}

export const dataURLtoFile = (dataurl, filename) => {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export function toCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function htmlToText(value) {
    return value.replace(/(<([^>]+)>)/gi, "");
}

export function digitsBeGone(str) {
    return str.match(/\D/g).join('')
}

export function convertCurrency(currencyList, to, input) {
    if (currencyList.length) {
        if (to) {
            const rate = _.filter(currencyList, function (o) { return o.code?.toString() === to?.toString(); });
            // var rate = currencyList [to];
            var convertedVal = input / (rate.length ? rate[0]?.amount : 0);
            return currencyFormat(convertedVal || 0)
            return parseFloat(convertedVal || 0).toFixed(2)
        }
    }
    return currencyFormat(input || 0)
}

export function convertDateFormat(itemData, def = "DD MMM YYYY HH:mm") {
    return itemData ? moment(itemData).format(def) : "";
}

export function getByGroup(arrItems, groupBy) {
    let items = []
    const groupArrayObject = arrItems.reduce((items, arr) => {

        const serviceGroupName = arr[groupBy];

        items[serviceGroupName] = items[serviceGroupName] ?? [];

        items[serviceGroupName].push(arr);

        return items;

    },

        {});
    return groupArrayObject;
}

export function checkSymble(string) {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if(format.test(string)){
    return true;
    } else {
    return false;
    }
}

export function toPascalCase (val, trimSpace = false) { 
// return val
    if(!val){
        return val
    }

    /* const arr = val
    .toLowerCase()
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .trim()
    .split(' ') */

    
    return val
    .toLowerCase()
    // .replace(new RegExp(/[-_]+/, 'g'), ' ')
    // .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .trim()
    .split(' ')
    .map(word => word[0]
    ?.toUpperCase()
    .concat(word.slice(1)))
    .join(' ');
    // text.split(' ').map((t) => t[0].toUpperCase() + t.slice(1).toLowerCase()).join(trimSpace?'':' ')
}

export function camelize(str) {
    if(!str){
        return str
    }
    // Using replace method with regEx
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
}

export function camelize_1(text) {
    if(text){
        return text
    }
    const a = text.toLowerCase()
        .replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
    return a.substring(0, 1).toLowerCase() + a.substring(1);
}

export function numToEng(n) {
    var string = n?.toString(), units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words, and = 'and';

    /* Remove spaces and commas */
    string = string?.replace(/[, ]/g, "");

    /* Is number zero? */
    if (parseInt(string) === 0) {
        return 'zero';
    }

    /* Array of units as words */
    units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];

    /* Array of tens as words */
    tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    /* Array of scales as words */
    scales = ['', 'Thousand', 'Million', 'Billion', 'Trillion', 'Quadrillion', 'Quintillion', 'Sextillion', 'Septillion', 'Octillion', 'Nonillion', 'Decillion', 'Undecillion', 'Duodecillion', 'Tredecillion', 'Quatttuor-Decillion', 'Quindecillion', 'Sexdecillion', 'Septen-Decillion', 'Octodecillion', 'Novemdecillion', 'Vigintillion', 'Centillion'];

    /* Split user argument into 3 digit chunks from right to left */
    start = string?.length || 0;
    chunks = [];
    while (start > 0) {
        end = start;
        chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
        return '';
    }

    /* Stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {

        chunk = parseInt(chunks[i]);

        if (chunk) {

            /* Split chunk into array of individual integers */
            ints = chunks[i].split('').reverse().map(parseFloat);

            /* If tens integer is 1, i.e. 10, then add 10 to units integer */
            if (ints[1] === 1) {
                ints[0] += 10;
            }

            /* Add scale word if chunk is not zero and array item exists */
            if ((word = scales[i])) {
                words.push(word);
            }

            /* Add unit word if array item exists */
            if ((word = units[ints[0]])) {
                words.push(word);
            }

            /* Add tens word if array item exists */
            if ((word = tens[ints[1]])) {
                words.push(word);
            }

            /* Add 'and' string after units or tens integer if: */
            if (ints[0] || ints[1]) {

                /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
                if (ints[2] || (i + 1) > chunksLen) {
                    words.push(and);
                }


            }

            /* Add hundreds word if array item exists */
            if ((word = units[ints[2]])) {
                words.push(word + ' Hundred');
            }

        }

    }

    return words.reverse().join(' ');
}