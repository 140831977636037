const initialState = {
    fittingList: []  
  }
  
  const FittingReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_FITTING_SUCCESS": {             
        return {
          ...state,
          getFittingSuccess: true,
          getFittingList: action.payload.data,
        };
      }
      case "GET_FITTING_FAILURE": {
        return {
          ...state,
          getFittingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_FITTING": {
        return {
          ...state,
          getFittingSuccess: false,
          getFittingFailure: false,
          getFittingList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_FITTING_SUCCESS": {             
        return {
          ...state,
          createFittingSuccess: true,
          createFittingData: action.payload.data,
        };
      }
      case "CREATE_FITTING_FAILURE": {
        return {
          ...state,
          createFittingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_FITTING": {
        return {
          ...state,
          createFittingSuccess: false,
          createFittingFailure: false,
          createFittingData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_FITTING_SUCCESS": {             
        return {
          ...state,
          updateFittingSuccess: true,
          updateFittingData: action.payload.data,
        };
      }
      case "UPDATE_FITTING_FAILURE": {
        return {
          ...state,
          updateFittingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_FITTING": {
        return {
          ...state,
          updateFittingSuccess: false,
          updateFittingFailure: false,
          updateFittingData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default FittingReducer
  
  