import { lazy } from "react";

const InvoiceSettingsList = lazy(() => import("./invoiceSettingsList"));

const invoiceSettingsRoutes = [
  {
    path: "/invoice-settings",
    element: <InvoiceSettingsList />,
  }
];

export default invoiceSettingsRoutes;
