import { lazy } from "react";

const PropertyTypeList = lazy(() => import("./propertyTypeList"));
const FireInsuranceList = lazy(() => import("./fireInsuranceList"));

const propertyTypeRoutes = [
  {
    path: "/fire-insurance",
    element: <FireInsuranceList />,
  },
  {
    path: "/property-type",
    element: <PropertyTypeList />,
  }
];

export default propertyTypeRoutes;
