const initialState = {
    dashboardServiceList: []  
  }
  
  const DashboardServiceReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DASHBOARD_SERVICE_SUCCESS": {             
        return {
          ...state,
          getDashBoardServiceSuccess: true,
          getDashBoardServiceList: action.payload.data,
        };
      }
      case "GET_DASHBOARD_SERVICE_FAILURE": {
        return {
          ...state,
          getDashBoardServiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DASHBOARD_SERVICE": {
        return {
          ...state,
          getDashBoardServiceSuccess: false,
          getDashBoardServiceFailure: false,
          getDashBoardServiceList: [],
          errorMessage: false
        };
      }

      case "GET_DASHBOARD_REPORT_SUCCESS": {             
        return {
          ...state,
          getDashBoardReportSuccess: true,
          getDashBoardReportList: action.payload.data,
        };
      }
      case "GET_DASHBOARD_REPORT_FAILURE": {
        return {
          ...state,
          getDashBoardReportFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DASHBOARD_REPORT": {
        return {
          ...state,
          getDashBoardReportSuccess: false,
          getDashBoardReportFailure: false,
          getDashBoardReportList: [],
          errorMessage: false
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DashboardServiceReducer
  
  