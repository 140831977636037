import { lazy } from "react";

const AirconList = lazy(() => import("./airconList"));

const airconRoutes = [
  {
    path: "/aircon",
    element: <AirconList />,
  }
];

export default airconRoutes;
