import { lazy } from "react";

const UninspectedReportList = lazy(() => import("./uninspectedReportList"));
const ClosedReportList = lazy(() => import("./closedReportList"));
const AbortedReportList = lazy(() => import("./abortedReportList"));
const InspectedReportList = lazy(() => import("./inspectedReportList"));
// import img from "../../../../public/assets/images/photo-wide-41.jpg"

const uninspectedReportRoutes = [
  {
    path: "/uninspected-report",
    element: <UninspectedReportList />,
  },
  {
    path: "/closed-report",
    element: <ClosedReportList />,
  },
  {
    path: "/aborted-report",
    element: <AbortedReportList />,
  },
  {
    path: "/inspected-report",
    element: <InspectedReportList />,
  },
];

export default uninspectedReportRoutes;
