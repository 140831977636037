const initialState = {
    tenancyList: []  
  }
  
  const TenancyReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_TENANCY_SUCCESS": {             
        return {
          ...state,
          getTenancySuccess: true,
          getTenancyList: action.payload.data,
        };
      }
      case "GET_TENANCY_FAILURE": {
        return {
          ...state,
          getTenancyFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TENANCY": {
        return {
          ...state,
          getTenancySuccess: false,
          getTenancyFailure: false,
          getTenancyList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_TENANCY_SUCCESS": {             
        return {
          ...state,
          createTenancySuccess: true,
          createTenancyData: action.payload.data,
        };
      }
      case "CREATE_TENANCY_FAILURE": {
        return {
          ...state,
          createTenancyFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_TENANCY": {
        return {
          ...state,
          createTenancySuccess: false,
          createTenancyFailure: false,
          createTenancyData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_TENANCY_SUCCESS": {             
        return {
          ...state,
          updateTenancySuccess: true,
          updateTenancyData: action.payload.data,
        };
      }
      case "UPDATE_TENANCY_FAILURE": {
        return {
          ...state,
          updateTenancyFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_TENANCY": {
        return {
          ...state,
          updateTenancySuccess: false,
          updateTenancyFailure: false,
          updateTenancyData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default TenancyReducer
  
  