import { lazy } from "react";

const ServiceFacilitiesList = lazy(() => import("./serviceFacilitiesList"));

const serviceFacilitiesRoutes = [
  {
    path: "/service-facilities",
    element: <ServiceFacilitiesList />,
  }
];

export default serviceFacilitiesRoutes;
