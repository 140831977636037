import useAuth from 'app/hooks/useAuth'
import { flat } from 'app/utils/utils'
import React, { useState, useEffect, useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import AppContext from '../contexts/AppContext'
import { AllPages } from '../routes/routes'

const getUserRoleAuthStatus = (pathname, user, routes) => {
    if (!user) {
        return false
    }
    const matched = routes.find((r) => r.path === pathname)

    const authenticated =
        matched && matched.auth && matched.auth.length
            ? matched.auth.includes(user.role)
            : true
    console.log(matched, user)
    return authenticated
}

const AuthGuard = ({ children, userInfo }) => {  

     
    // const dddd = useAuth()
    // const { isAuthenticated, user } = userInfo
    // alert(JSON.stringify(isAuthenticated))
    // const { isAuthenticated, user } = useAuth()
    let isAuthenticated = false;
    const accessToken = window.localStorage.getItem('accessToken')
    if (accessToken) {
        const userInfo = window.localStorage.getItem('userInfo');
        global.UserInfo = JSON.parse(userInfo)

        const pageItem = window.localStorage.getItem('page');
        global.pageItems = JSON.parse(pageItem)

        // setSession(accessToken)
        /* const response = await axios.get('/api/auth/profile')
        const { user } = response.data */
        // const userData = getSession('user');
        // const user = userData ? JSON.parse(userData) : false
       isAuthenticated = true
    } else {
        global.UserInfo = false;
        isAuthenticated = false
    }
    // alert(accessToken)
    // return <>{isAuthenticated ? children : <Navigate to="/session/signin" />}</>

    const [previouseRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()
    // const routes = flat(AllPages())

    // console.log(user)

    /* const isUserRoleAuthenticated = getUserRoleAuthStatus(
        pathname,
        user,
        routes
    )
    let authenticated = isAuthenticated && isUserRoleAuthenticated */

    // IF YOU NEED ROLE BASED AUTHENTICATION,
    // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
    // AND COMMENT OUT BELOW LINE

    let authenticated = isAuthenticated
    
    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute])
    // alert(authenticated)
    // console.log("children",(pathname))
    if (authenticated) return <>{children}</>
    else {
        return (
            <>
            {/* {children} */}
            <Navigate
                to="/sessions/signin"
                state={{ redirectUrl: previouseRoute }}
            />
            </>
            // <Redirect
            //     to={{
            //         pathname: '/session/signin',
            //         state: { redirectUrl: previouseRoute },
            //     }}
            // />
        )
    }
}

export default AuthGuard
