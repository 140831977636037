import { lazy } from "react";

const FacingList = lazy(() => import("./facingList"));

const facingRoutes = [
  {
    path: "/facing",
    element: <FacingList />,
  }
];

export default facingRoutes;
