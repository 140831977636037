const initialState = {
    reasonList: []  
  }
  
  const ReasonReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_REASON_SUCCESS": {             
        return {
          ...state,
          getReasonSuccess: true,
          getReasonList: action.payload.data,
        };
      }
      case "GET_REASON_FAILURE": {
        return {
          ...state,
          getReasonFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REASON": {
        return {
          ...state,
          getReasonSuccess: false,
          getReasonFailure: false,
          getReasonList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_REASON_SUCCESS": {             
        return {
          ...state,
          createReasonSuccess: true,
          createReasonData: action.payload.data,
        };
      }
      case "CREATE_REASON_FAILURE": {
        return {
          ...state,
          createReasonFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_REASON": {
        return {
          ...state,
          createReasonSuccess: false,
          createReasonFailure: false,
          createReasonData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_REASON_SUCCESS": {             
        return {
          ...state,
          updateReasonSuccess: true,
          updateReasonData: action.payload.data,
        };
      }
      case "UPDATE_REASON_FAILURE": {
        return {
          ...state,
          updateReasonFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_REASON": {
        return {
          ...state,
          updateReasonSuccess: false,
          updateReasonFailure: false,
          updateReasonData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ReasonReducer
  
  