import { baseURL, ports } from "app/utils/constant";
import axios from "axios";
import { comparablesApi, uploadLi } from "./APIContainer";

function getBaseUrl(url, type) {
  /* axios.defaults.baseURL = process.env.REACT_APP_API_URL  
  return url; */
  if (url === "login") {    
    axios.defaults.baseURL = baseURL+ports.access;    
  }
  else if (url.includes("access-management")) {    
    axios.defaults.baseURL = baseURL+ports.access;    
  }
  else if (url.includes("home-management")) {    
    axios.defaults.baseURL = baseURL+ports.home;    
  }
  else if (url.includes("report-management")) {    
    axios.defaults.baseURL = baseURL+ports.report;    
  }
  else if (url.includes("document-management")) {
    axios.defaults.baseURL = baseURL+ports.document;    
  }
  else {
    axios.defaults.baseURL = baseURL+ports.payroll;    
  }
  return url;
}

export function getAPIUrl(url) {
  let baseURLVal = baseURL+ports.payroll;
  baseURLVal = baseURLVal+url
  return baseURLVal;
}

export function returnApiCallPromise(method, url, object = null, config = null) {  
  /* axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers['Cache-Control'] = 'no-cache';
  axios.defaults.headers['If-Modified-Since'] = 0; */

  
  if(url === comparablesApi){
    axios.defaults.headers['Token'] = '88dca740d1fddee7643f8bb61a96db6a111fbdf224389121a614c8d5e7b1819a';
  } else if(url.includes(uploadLi)){
    delete axios.defaults.headers["user_id"];
    delete axios.defaults.headers["user_type"];
    delete axios.defaults.headers["platform_type"];
  } else {
    const userTypeId = global.UserInfo?.userInfo?.userTypeId || 0
    const userId = global.UserInfo?.userId || 0
    axios.defaults.headers['user_id'] = userId;
    axios.defaults.headers['user_type'] = userTypeId;
    axios.defaults.headers['platform_type'] = 'Web';
  }
  /* const userTypeId = global.UserInfo?.userInfo?.userTypeId || 0
  const userId = global.UserInfo?.userId || 0
  axios.defaults.headers['user_id'] = userId;
  axios.defaults.headers['user_type'] = userTypeId; */
  /* else {
    const userTypeId = global.UserInfo?.userInfo?.userTypeId || 0
    const userId = global.UserInfo?.userId || 0
    axios.defaults.headers['user_id'] = userId;
  } */
  


  //axios.create({withCredentials : true});
  // axios.defaults.headers['org_id'] = global.OrgID ? global.OrgID : 0;
  // axios.defaults.headers['branchid'] = global.branchId ? global.branchId : 0;
  // axios.defaults.headers['usercode'] = global.usercode;  
  // axios.defaults.headers['counterid'] = global.counterId ? global.counterId : 0;
  // eslint-disable-next-line default-case
  /* headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    // 'csrftoken': 'AIZNfd0V5x0tr6wizIq0qMqFIEdNXJryiGV8nSqgXLm4idXhFCtLcV5CKDZZkKpS',
    // 'Content-Length': JSON.stringify(object).length,
    'content-type': 'multipart/form-data'
  } */

  // axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
  // axios.defaults.headers["Access-Control-Allow-Origin"] = "*"

  switch (method) {
    case 'GET':      
      return axios.get(getBaseUrl(url), object, config);
    case 'POST':      
      return axios.post(getBaseUrl(url), object, config);
    case 'PUT':
      return axios.put(getBaseUrl(url), object, config);
    case 'DELETE':
      return axios.delete(getBaseUrl(url), object, config);
    case 'FORMPUT':
      const configs = {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Length': JSON.stringify(object).length,
          'content-type': 'multipart/form-data'
        }
      }
      return axios.put(getBaseUrl(url), object, configs);
    case 'FORMPOST':
      const postConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Access-Control-Allow-Origin': '*',
          // 'csrftoken': 'AIZNfd0V5x0tr6wizIq0qMqFIEdNXJryiGV8nSqgXLm4idXhFCtLcV5CKDZZkKpS',
          // 'Content-Length': JSON.stringify(object).length,
          'content-type': 'multipart/form-data'
        }
      }
      return axios.post(getBaseUrl(url), object, postConfig);
  }
}
