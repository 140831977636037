const initialState = {
    serviceFacilitiesList: []
}

const SignatoryReducer = function (state = initialState, action) {
    switch (action.type) {
        case "CREATE_INVOICE_DOCUMENT_SUCCESS": {
            return {
                ...state,
                createInvoiceDocumentSuccess: true,
                createInvoiceDocumentList: action.payload.data,
            };
        }
        case "CREATE_INVOICE_DOCUMENT_FAILED": {
            return {
                ...state,
                createInvoiceDocumentFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_CREATE_INVOICE_DOCUMENT": {
            return {
                ...state,
                createInvoiceDocumentSuccess: false,
                createInvoiceDocumentFailure: false,
                createInvoiceDocumentList: [],
                errorMessage: false
            };
        }
        
        default: {
            return {
                ...state,
            }
        }
    }
}

export default SignatoryReducer

