const initialState = {
    uploadLiList: []
}

const UploadLiReducer = function (state = initialState, action) {
    switch (action.type) {
        case "GET_UPLOAD_LI_SUCCESS": {
            return {
                ...state,
                getUploadLiSuccess: true,
                getUploadLiData: action.payload,
            };
        }
        case "GET_UPLOAD_LI_FAILURE": {
            return {
                ...state,
                getUploadLiFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_UPLOAD_LI": {
            return {
                ...state,
                getUploadLiSuccess: false,
                getUploadLiFailure: false,
                getUploadLiData: false,
                errorMessage: false
            };
        }


        case "CREATE_UPLOAD_LI_SUCCESS": {
            return {
                ...state,
                createUploadLiSuccess: true,
                createUploadLiData: action.payload,
            };
        }
        case "CREATE_UPLOAD_LI_FAILURE": {
            return {
                ...state,
                createUploadLiFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_UPLOAD_LI": {
            return {
                ...state,
                createUploadLiSuccess: false,
                createUploadLiFailure: false,
                createUploadLiData: false,
                errorMessage: false
            };
        }



        case "CREATE_UPLOAD_LOCATION_SUCCESS": {
            return {
                ...state,
                createUploadLocationSuccess: true,
                createUploadLocationData: action.payload,
            };
        }
        case "CREATE_UPLOAD_LOCATION_FAILURE": {
            return {
                ...state,
                createUploadLocationFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_UPLOAD_LOCATION": {
            return {
                ...state,
                createUploadLocationSuccess: false,
                createUploadLocationFailure: false,
                createUploadLocationData: false,
                errorMessage: false
            };
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default UploadLiReducer

