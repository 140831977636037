import { lazy } from "react";

const ReportTemplateFieldsList = lazy(() => import("./reportTemplateFieldsList"));

const reportTemplateFieldsRoutes = [
  {
    path: "/report-template-fields",
    element: <ReportTemplateFieldsList />,
  }
];

export default reportTemplateFieldsRoutes;
