import { lazy } from "react";

const DepartmentsList = lazy(() => import("./departmentsList"));

const departmentsRoutes = [
  {
    path: "/departments",
    element: <DepartmentsList />,
  }
];

export default departmentsRoutes;
