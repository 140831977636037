const initialState = {
    propertyTypeList: []  
  }
  
  const PropertyTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_PROPERTY_TYPE_SUCCESS": {             
        return {
          ...state,
          getPropertyTypeSuccess: true,
          getPropertyTypeList: action.payload.data,
        };
      }
      case "GET_PROPERTY_TYPE_FAILURE": {
        return {
          ...state,
          getPropertyTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PROPERTY_TYPE": {
        return {
          ...state,
          getPropertyTypeSuccess: false,
          getPropertyTypeFailure: false,
          getPropertyTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_PROPERTY_TYPE_SUCCESS": {             
        return {
          ...state,
          createPropertyTypeSuccess: true,
          createPropertyTypeData: action.payload.data,
        };
      }
      case "CREATE_PROPERTY_TYPE_FAILURE": {
        return {
          ...state,
          createPropertyTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_PROPERTY_TYPE": {
        return {
          ...state,
          createPropertyTypeSuccess: false,
          createPropertyTypeFailure: false,
          createPropertyTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_PROPERTY_TYPE_SUCCESS": {             
        return {
          ...state,
          updatePropertyTypeSuccess: true,
          updatePropertyTypeData: action.payload.data,
        };
      }
      case "UPDATE_PROPERTY_TYPE_FAILURE": {
        return {
          ...state,
          updatePropertyTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_PROPERTY_TYPE": {
        return {
          ...state,
          updatePropertyTypeSuccess: false,
          updatePropertyTypeFailure: false,
          updatePropertyTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default PropertyTypeReducer
  
  