import { lazy } from "react";

const ZoningList = lazy(() => import("./zoningList"));

const zoningRoutes = [
  {
    path: "/zoning",
    element: <ZoningList />,
  }
];

export default zoningRoutes;
