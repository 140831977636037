const initialState = {
    invoiceSettingsList: []  
  }
  
  const InvoiceSettingsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_INVOICE_SETTINGS_SUCCESS": {             
        return {
          ...state,
          getInvoiceSettingsSuccess: true,
          getInvoiceSettingsList: action.payload.data,
        };
      }
      case "GET_INVOICE_SETTINGS_FAILURE": {
        return {
          ...state,
          getInvoiceSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_INVOICE_SETTINGS": {
        return {
          ...state,
          getInvoiceSettingsSuccess: false,
          getInvoiceSettingsFailure: false,
          getInvoiceSettingsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_INVOICE_SETTINGS_SUCCESS": {             
        return {
          ...state,
          createInvoiceSettingsSuccess: true,
          createInvoiceSettingsData: action.payload.data,
        };
      }
      case "CREATE_INVOICE_SETTINGS_FAILURE": {
        return {
          ...state,
          createInvoiceSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_INVOICE_SETTINGS": {
        return {
          ...state,
          createInvoiceSettingsSuccess: false,
          createInvoiceSettingsFailure: false,
          createInvoiceSettingsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_INVOICE_SETTINGS_SUCCESS": {             
        return {
          ...state,
          updateInvoiceSettingsSuccess: true,
          updateInvoiceSettingsData: action.payload.data,
        };
      }
      case "UPDATE_INVOICE_SETTINGS_FAILURE": {
        return {
          ...state,
          updateInvoiceSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_INVOICE_SETTINGS": {
        return {
          ...state,
          updateInvoiceSettingsSuccess: false,
          updateInvoiceSettingsFailure: false,
          updateInvoiceSettingsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default InvoiceSettingsReducer
  
  