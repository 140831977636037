import { lazy } from "react";

const TenancyList = lazy(() => import("./tenancyList"));

const tenancyRoutes = [
  {
    path: "/tenancy",
    element: <TenancyList />,
  }
];

export default tenancyRoutes;
