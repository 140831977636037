const initialState = {
    floorList: []  
  }
  
  const FloorReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_FLOOR_SUCCESS": {             
        return {
          ...state,
          getFloorSuccess: true,
          getFloorList: action.payload.data,
        };
      }
      case "GET_FLOOR_FAILURE": {
        return {
          ...state,
          getFloorFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_FLOOR": {
        return {
          ...state,
          getFloorSuccess: false,
          getFloorFailure: false,
          getFloorList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_FLOOR_SUCCESS": {             
        return {
          ...state,
          createFloorSuccess: true,
          createFloorData: action.payload.data,
        };
      }
      case "CREATE_FLOOR_FAILURE": {
        return {
          ...state,
          createFloorFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_FLOOR": {
        return {
          ...state,
          createFloorSuccess: false,
          createFloorFailure: false,
          createFloorData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_FLOOR_SUCCESS": {             
        return {
          ...state,
          updateFloorSuccess: true,
          updateFloorData: action.payload.data,
        };
      }
      case "UPDATE_FLOOR_FAILURE": {
        return {
          ...state,
          updateFloorFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_FLOOR": {
        return {
          ...state,
          updateFloorSuccess: false,
          updateFloorFailure: false,
          updateFloorData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default FloorReducer
  
  