const initialState = {
    indicativeValuationList: []  
  }
  
  const IndicativeValuationReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_INDICATIVE_VALUATION_SUCCESS": {             
        return {
          ...state,
          getIndicativeValuationSuccess: true,
          getIndicativeValuationList: action.payload.data,
        };
      }
      case "GET_INDICATIVE_VALUATION_FAILURE": {
        return {
          ...state,
          getIndicativeValuationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_INDICATIVE_VALUATION": {
        return {
          ...state,
          getIndicativeValuationSuccess: false,
          getIndicativeValuationFailure: false,
          getIndicativeValuationList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_INDICATIVE_VALUATION_SUCCESS": {             
        return {
          ...state,
          createIndicativeValuationSuccess: true,
          createIndicativeValuationData: action.payload.data,
        };
      }
      case "CREATE_INDICATIVE_VALUATION_FAILURE": {
        return {
          ...state,
          createIndicativeValuationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_INDICATIVE_VALUATION": {
        return {
          ...state,
          createIndicativeValuationSuccess: false,
          createIndicativeValuationFailure: false,
          createIndicativeValuationData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_INDICATIVE_VALUATION_SUCCESS": {             
        return {
          ...state,
          updateIndicativeValuationSuccess: true,
          updateIndicativeValuationData: action.payload.data,
        };
      }
      case "UPDATE_INDICATIVE_VALUATION_FAILURE": {
        return {
          ...state,
          updateIndicativeValuationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_INDICATIVE_VALUATION": {
        return {
          ...state,
          updateIndicativeValuationSuccess: false,
          updateIndicativeValuationFailure: false,
          updateIndicativeValuationData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default IndicativeValuationReducer
  
  