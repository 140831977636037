const initialState = {
    uninspectedCaseList: []  
  }
  
  const UninspectedCaseReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_UNINSPECTED_CASE_SUCCESS": {             
        return {
          ...state,
          getUninspectedCaseSuccess: true,
          getUninspectedCaseList: action.payload.data,
        };
      }
      case "GET_UNINSPECTED_CASE_FAILURE": {
        return {
          ...state,
          getUninspectedCaseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_UNINSPECTED_CASE": {
        return {
          ...state,
          getUninspectedCaseSuccess: false,
          getUninspectedCaseFailure: false,
          getUninspectedCaseList: [],
          errorMessage: false
        };
      }

      case "GET_REASON_SUCCESS": {             
        return {
          ...state,
          getReasonSuccess: true,
          getReasonList: action.payload.data,
        };
      }
      case "GET_REASON_FAILURE": {
        return {
          ...state,
          getReasonFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REASON": {
        return {
          ...state,
          getReasonSuccess: false,
          getReasonFailure: false,
          getReasonList: [],
          errorMessage: false
        };
      }

      case "GET_CASE_SUCCESS": {             
        return {
          ...state,
          getCaseSuccess: true,
          getCaseList: action.payload.data,
        };
      }
      case "GET_CASE_FAILURE": {
        return {
          ...state,
          getCaseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CASE": {
        return {
          ...state,
          getCaseSuccess: false,
          getCaseFailure: false,
          getCaseList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_UNINSPECTED_CASE_SUCCESS": {             
        return {
          ...state,
          createUninspectedCaseSuccess: true,
          createUninspectedCaseData: action.payload.data,
        };
      }
      case "CREATE_UNINSPECTED_CASE_FAILURE": {
        return {
          ...state,
          createUninspectedCaseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_UNINSPECTED_CASE": {
        return {
          ...state,
          createUninspectedCaseSuccess: false,
          createUninspectedCaseFailure: false,
          createUninspectedCaseData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_UNINSPECTED_CASE_SUCCESS": {             
        return {
          ...state,
          updateUninspectedCaseSuccess: true,
          updateUninspectedCaseData: action.payload.data,
        };
      }
      case "UPDATE_UNINSPECTED_CASE_FAILURE": {
        return {
          ...state,
          updateUninspectedCaseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_UNINSPECTED_CASE": {
        return {
          ...state,
          updateUninspectedCaseSuccess: false,
          updateUninspectedCaseFailure: false,
          updateUninspectedCaseData: false,
          errorMessage: false         
        };
      }





      case "GET_COMPARABLES_SUCCESS": {             
        return {
          ...state,
          getComparablesSuccess: true,
          getComparablesData: action.payload.data,
        };
      }
      case "GET_COMPARABLES_FAILURE": {
        return {
          ...state,
          getComparablesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_COMPARABLES": {
        return {
          ...state,
          getComparablesSuccess: false,
          getComparablesFailure: false,
          getComparablesData: false,
          errorMessage: false         
        };
      }

      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default UninspectedCaseReducer
  
  