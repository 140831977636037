const initialState = {
  companySettingsList: []
}

const CompanySettingsReducer = function (state = initialState, action) {
  switch (action.type) {
    case "GET_COMPANY_SETTINGS_SUCCESS": {
      return {
        ...state,
        getCompanySettingsSuccess: true,
        getCompanySettingsList: action.payload.data,
      };
    }
    case "GET_COMPANY_SETTINGS_FAILURE": {
      return {
        ...state,
        getCompanySettingsFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_COMPANY_SETTINGS": {
      return {
        ...state,
        getCompanySettingsSuccess: false,
        getCompanySettingsFailure: false,
        getCompanySettingsList: [],
        errorMessage: false
      };
    }

    case "CREATE_COMPANY_SETTINGS_SUCCESS": {
      return {
        ...state,
        createCompanySettingsSuccess: true,
        createCompanySettingsData: action.payload.data,
      };
    }
    case "CREATE_COMPANY_SETTINGS_FAILURE": {
      return {
        ...state,
        createCompanySettingsFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_CREATE_COMPANY_SETTINGS": {
      return {
        ...state,
        createCompanySettingsSuccess: false,
        createCompanySettingsFailure: false,
        createCompanySettingsData: false,
        errorMessage: false
      };
    }

    case "UPDATE_COMPANY_SETTINGS_SUCCESS": {
      return {
        ...state,
        updateCompanySettingsSuccess: true,
        updateCompanySettingsData: action.payload.data,
      };
    }
    case "UPDATE_COMPANY_SETTINGS_FAILURE": {
      return {
        ...state,
        updateCompanySettingsFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_COMPANY_SETTINGS": {
      return {
        ...state,
        updateCompanySettingsSuccess: false,
        updateCompanySettingsFailure: false,
        updateCompanySettingsData: false,
        errorMessage: false
      };
    }


    case "GET_CONSTRUCTION_FINISHES_SUCCESS": {
      return {
        ...state,
        getConstructionFinishesSuccess: true,
        getConstructionFinishesData: action.payload.data,
      };
    }
    case "GET_CONSTRUCTION_FINISHES_FAILURE": {
      return {
        ...state,
        getConstructionFinishesFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_CONSTRUCTION_FINISHES": {
      return {
        ...state,
        getConstructionFinishesSuccess: false,
        getConstructionFinishesFailure: false,
        getConstructionFinishesData: [],
        errorMessage: false
      };
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default CompanySettingsReducer

