const initialState = {
    reportList: []  
  }
  
  const ReportFieldsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_REPORT_FIELDS_SUCCESS": {             
        return {
          ...state,
          getReportFieldsSuccess: true,
          getReportFieldsList: action.payload.data,
        };
      }
      case "GET_REPORT_FIELDS_FAILURE": {
        return {
          ...state,
          getReportFieldsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REPORT_FIELDS": {
        return {
          ...state,
          getReportFieldsSuccess: false,
          getReportFieldsFailure: false,
          getReportFieldsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_REPORT_FIELDS_SUCCESS": {             
        return {
          ...state,
          createReportFieldsSuccess: true,
          createReportFieldsData: action.payload.data,
        };
      }
      case "CREATE_REPORT_FIELDS_FAILURE": {
        return {
          ...state,
          createReportFieldsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_REPORT_FIELDS": {
        return {
          ...state,
          createReportFieldsSuccess: false,
          createReportFieldsFailure: false,
          createReportFieldsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_REPORT_FIELDS_SUCCESS": {             
        return {
          ...state,
          updateReportFieldsSuccess: true,
          updateReportFieldsData: action.payload.data,
        };
      }
      case "UPDATE_REPORT_FIELDS_FAILURE": {
        return {
          ...state,
          updateReportFieldsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_REPORT_FIELDS": {
        return {
          ...state,
          updateReportFieldsSuccess: false,
          updateReportFieldsFailure: false,
          updateReportFieldsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ReportFieldsReducer
  
  