const initialState = {
    windowsList: []  
  }
  
  const WindowsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_WINDOWS_SUCCESS": {             
        return {
          ...state,
          getWindowsSuccess: true,
          getWindowsList: action.payload.data,
        };
      }
      case "GET_WINDOWS_FAILURE": {
        return {
          ...state,
          getWindowsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_WINDOWS": {
        return {
          ...state,
          getWindowsSuccess: false,
          getWindowsFailure: false,
          getWindowsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_WINDOWS_SUCCESS": {             
        return {
          ...state,
          createWindowsSuccess: true,
          createWindowsData: action.payload.data,
        };
      }
      case "CREATE_WINDOWS_FAILURE": {
        return {
          ...state,
          createWindowsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_WINDOWS": {
        return {
          ...state,
          createWindowsSuccess: false,
          createWindowsFailure: false,
          createWindowsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_WINDOWS_SUCCESS": {             
        return {
          ...state,
          updateWindowsSuccess: true,
          updateWindowsData: action.payload.data,
        };
      }
      case "UPDATE_WINDOWS_FAILURE": {
        return {
          ...state,
          updateWindowsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_WINDOWS": {
        return {
          ...state,
          updateWindowsSuccess: false,
          updateWindowsFailure: false,
          updateWindowsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default WindowsReducer
  
  