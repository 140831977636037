import { lazy } from "react";

const ScheduleList = lazy(() => import("./scheduleList"));

const scheduleRoutes = [
  {
    path: "/schedule",
    element: <ScheduleList />,
  }
];

export default scheduleRoutes;
