const initialState = {
    documentList: []  
  }
  
  const DocumentReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DOCUMENT_SUCCESS": {             
        return {
          ...state,
          getDocumentSuccess: true,
          getDocumentList: action.payload.data,
        };
      }
      case "GET_DOCUMENT_FAILURE": {
        return {
          ...state,
          getDocumentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DOCUMENT": {
        return {
          ...state,
          getDocumentSuccess: false,
          getDocumentFailure: false,
          getDocumentList: [],
          errorMessage: false
        };
      }

      case "DELETE_DOCUMENT_SUCCESS": {             
        return {
          ...state,
          deleteDocumentSuccess: true,
        };
      }
      case "DELETE_DOCUMENT_FAILURE": {
        return {
          ...state,
          deleteDocumentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_DELETE_DOCUMENT": {
        return {
          ...state,
          deleteDocumentSuccess: false,
          deleteDocumentFailure: false,
          errorMessage: false         
        };
      }
      
      case "CREATE_DOCUMENT_SUCCESS": {             
        return {
          ...state,
          createDocumentSuccess: true,
          createDocumentData: action.payload.data,
        };
      }
      case "CREATE_DOCUMENT_FAILURE": {
        return {
          ...state,
          createDocumentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_DOCUMENT": {
        return {
          ...state,
          createDocumentSuccess: false,
          createDocumentFailure: false,
          createDocumentData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_DOCUMENT_SUCCESS": {             
        return {
          ...state,
          updateDocumentSuccess: true,
          updateDocumentData: action.payload.data,
        };
      }
      case "UPDATE_DOCUMENT_FAILURE": {
        return {
          ...state,
          updateDocumentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_DOCUMENT": {
        return {
          ...state,
          updateDocumentSuccess: false,
          updateDocumentFailure: false,
          updateDocumentData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DocumentReducer
  
  