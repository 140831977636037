import { lazy } from "react";

const StateRepairList = lazy(() => import("./stateRepairList"));

const stateRepairRoutes = [
    {
        path: "/state-repair",
        element: <StateRepairList />,
    }
];

export default stateRepairRoutes;
