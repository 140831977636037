import React from 'react';
import _ from "lodash";
import AuthGuard from 'app/auth/AuthGuard'

import sessionsRoutes from "app/views/sessions/sessionsRoutes";
import dataTableRoute from "app/views/dataTable/dataTableRoute";
import invoiceRoutes from "app/views/app/invoice/invoiceRoutes";
import inboxRoutes from "app/views/app/inbox/inboxRoutes";
import chatRoutes from "app/views/app/chat/chatRoutes";
import calendarRoutes from "app/views/app/calendar/calendarRoutes";
import taskManagerRoutes from "app/views/app/task-manager/taskManagerRoutes";
import contactRoutes from "app/views/app/contact/contactRoutes";
import Error404 from "app/views/sessions/Error";
import dashboardRoutes from "app/views/dashboard/dashboardRoutes";
import chartsRoute from "app/views/charts/chartsRoute";
import { Navigate, Outlet } from 'react-router-dom'
import GullLayout from 'app/GullLayout/GullLayout';

import indicativeValuationRoutes from "app/views/indicative-valuation/indicativeValuationRoutes";/* /indicative-valuation/indicativeValuationRoutes */
import unInspectedRoutes from "app/views/uninspected-case/uninspected-case-route";
import inspectedCaseRoutes from "app/views/inspected-case/inspectedCaseRoutes";
import closedCaseRoutes from "app/views/closed-case/closedCaseRoutes";
import abortedCaseRoutes from "app/views/aborted-case/abortedCaseRoutes";
import manageInvoiceRoutes from "app/views/invoice/invoiceRoutes";
import requestorRoutes from "app/views/requestors/requestorRoutes";
import roleRoutes from "app/views/role/roleRoutes";
import propertyTypeRoutes from "app/views/property-type/propertyTypeRoutes";
import tenancyRoutes from "app/views/tenancy/tenancyRoutes";
import accomodationRoutes from "app/views/accomodation/accomodationRoutes";
import floorRoutes from "app/views/floor/floorRoutes";
import wallRoutes from "app/views/wall/wallRoutes";
import ceilingRoutes from "app/views/ceiling/ceilingRoutes";
import airconRoutes from "app/views/aircon/airconRoutes";
import userRoutes from "app/views/user/user-route";
import windowsRoutes from "app/views/windows/windowsRoutes";
import doorsRoutes from "app/views/doors/doorsRoutes";
import fittingRoutes from "app/views/fitting/fittingRoutes";
import timeSlotRoutes from "app/views/timeSlot/timeSlotRoutes";
import invoiceSettingsRoutes from "app/views/invoice-settings/invoiceSettingsRoutes";
import uninspectedReportRoutes from "app/views/report/UninspectedReportRoutes";
import departmentsRoutes from 'app/views/departments/departmentsRoutes';
import facingRoutes from 'app/views/facing/facingRoutes';
import scheduleRoutes from 'app/views/schedular/scheduleRoutes';
import zoneRoutes from 'app/views/zone/zoneRoutes';
import zonePincodeRoutes from 'app/views/zonePincode/zonePincodeRoutes';
import documentTypeRoutes from 'app/views/document-type/documentTypeRoutes';
import qcReportRoutes from 'app/views/QC-report/qcReportRoutes';
import companyBankSettingsRoutes from 'app/views/settings/company-bank-settings/companyBankSettingsRoutes';
import headerSettingsRoutes from 'app/views/settings/report-settings/headerSettingsRoutes';
import companySettingsRoutes from 'app/views/settings/company-settings/companySettingsRoutes';
import signatoryRoutes from 'app/views/signatory/signatoryRoutes';
import reportTemplateFieldsRoutes from 'app/views/reportTemplateFields/reportTemplateFieldsRoutes';
import lightingRoutes from 'app/views/lighting/lightingRoutes';
import subReportTypeRoutes from 'app/views/sub-report-type/subReportTypeRoutes';
import serviceFacilitiesRoutes from 'app/views/service-facilities/serviceFacilitiesRoutes';
import stateRepairRoutes from 'app/views/state-repair/stateRepairRoutes';
import purposeRoutes from 'app/views/purpose/purposeRoutes';
import encumbrancesRoutes from 'app/views/encumbrances/encumbrancesRoutes';
import zoningRoutes from 'app/views/zoning/zoningRoutes';
import bankRoutes from 'app/views/bank/bankRoutes';


export const AllPages = () => {
    const routeItem = [...dataTableRoute, ...invoiceRoutes, ...inboxRoutes, ...calendarRoutes,
        ...taskManagerRoutes, ...contactRoutes, ...chatRoutes, ...dashboardRoutes, ...chartsRoute,
        ...indicativeValuationRoutes,...unInspectedRoutes,...inspectedCaseRoutes, ...closedCaseRoutes,...abortedCaseRoutes,
        ...manageInvoiceRoutes, ...requestorRoutes,...userRoutes, ...roleRoutes, ...propertyTypeRoutes,
        ...tenancyRoutes, ...accomodationRoutes, ...floorRoutes,
        ...wallRoutes, ...ceilingRoutes, ...airconRoutes, ...windowsRoutes, ...doorsRoutes, ...fittingRoutes,
        ...timeSlotRoutes, ...invoiceSettingsRoutes, ...uninspectedReportRoutes,...departmentsRoutes,...facingRoutes,
        ...scheduleRoutes,...zoneRoutes, ...zonePincodeRoutes,...documentTypeRoutes, ...qcReportRoutes, ...companyBankSettingsRoutes,
        ...headerSettingsRoutes, ...companySettingsRoutes, ...signatoryRoutes, ...reportTemplateFieldsRoutes, ...lightingRoutes, ...subReportTypeRoutes, 
        ...serviceFacilitiesRoutes, ...stateRepairRoutes, ...purposeRoutes, ...encumbrancesRoutes, ...zoningRoutes, ...bankRoutes
    ];
    const loc_route = localStorage.getItem("route-list");
    const loc_route_list = JSON.parse(loc_route ? loc_route : "[]");
    const sss = _.filter(routeItem,(o)=>{
        if(loc_route_list.length > 0){
            return (loc_route_list||[]).includes(o.path);
        }else{  
            return o.path
        }
        
    })
    // console.log(JSON.stringify(sss))
    const all_routes = [
        {
            element: (
                <AuthGuard >
                    <GullLayout>
                        <Outlet />
                    </GullLayout>                    
                </AuthGuard>
            ),
            children: sss,
        },
        ...sessionsRoutes,
        {
            path: '/',
            // element: <Navigate to="dashboard/default" />,
            element: <Navigate to="dashboard" />,
        },
        {
            path: '*',
            element: <Error404 />,
        },
    ]

    return all_routes
}
