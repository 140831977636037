const initialState = {
    requestorList: []  
  }
  
  const RequestorReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_BANK_SUCCESS": {             
        return {
          ...state,
          getBankSuccess: true,
          getBankList: action.payload.data,
        };
      }
      case "GET_BANK_FAILURE": {
        return {
          ...state,
          getBankFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_BANK": {
        return {
          ...state,
          getBankSuccess: false,
          getBankFailure: false,
          getBankList: [],
          errorMessage: false
        };
      }


      case "CREATE_BANK_SUCCESS": {             
        return {
          ...state,
          createBankSuccess: true,
          createBankData: action.payload.data,
        };
      }
      case "CREATE_BANK_FAILURE": {
        return {
          ...state,
          createBankFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_BANK": {
        return {
          ...state,
          createBankSuccess: false,
          createBankFailure: false,
          createBankData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_BANK_SUCCESS": {             
        return {
          ...state,
          updateBankSuccess: true,
          updateBankData: action.payload.data,
        };
      }
      case "UPDATE_BANK_FAILURE": {
        return {
          ...state,
          updateBankFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_BANK": {
        return {
          ...state,
          updateBankSuccess: false,
          updateBankFailure: false,
          updateBankData: false,
          errorMessage: false         
        };
      }


      case "GET_REQUESTOR_SUCCESS": {             
        return {
          ...state,
          getRequestorSuccess: true,
          getRequestorList: action.payload.data,
        };
      }
      case "GET_REQUESTOR_FAILURE": {
        return {
          ...state,
          getRequestorFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REQUESTOR": {
        return {
          ...state,
          getRequestorSuccess: false,
          getRequestorFailure: false,
          getRequestorList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_REQUESTOR_SUCCESS": {             
        return {
          ...state,
          createRequestorSuccess: true,
          createRequestorData: action.payload.data,
        };
      }
      case "CREATE_REQUESTOR_FAILURE": {
        return {
          ...state,
          createRequestorFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_REQUESTOR": {
        return {
          ...state,
          createRequestorSuccess: false,
          createRequestorFailure: false,
          createRequestorData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_REQUESTOR_SUCCESS": {             
        return {
          ...state,
          updateRequestorSuccess: true,
          updateRequestorData: action.payload.data,
        };
      }
      case "UPDATE_REQUESTOR_FAILURE": {
        return {
          ...state,
          updateRequestorFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_REQUESTOR": {
        return {
          ...state,
          updateRequestorSuccess: false,
          updateRequestorFailure: false,
          updateRequestorData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default RequestorReducer
  
  