import { lazy } from "react";

const RequestorList = lazy(() => import("./requestorList"));

const requestorRoutes = [
  {
    path: "/requestor",
    element: <RequestorList />,
  }
];

export default requestorRoutes;
