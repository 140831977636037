import { lazy } from "react";

const FittingList = lazy(() => import("./fittingList"));

const fittingRoutes = [
  {
    path: "/fittings",
    element: <FittingList />,
  }
];

export default fittingRoutes;
