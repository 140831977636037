import { lazy } from "react";

const TimeSlotList = lazy(() => import("./timeSlotList"));

const timeSlotRoutes = [
  {
    path: "/time-slot",
    element: <TimeSlotList />,
  }
];

export default timeSlotRoutes;
