import { lazy } from "react";

const DocumentTypeList = lazy(() => import("./documentTypeList"));

const documentTypeRoutes = [
  {
    path: "/document-type",
    element: <DocumentTypeList />,
  }
];

export default documentTypeRoutes;
