import { lazy } from "react";

const ManageInvoiceList = lazy(() => import("./manageInvoiceList"));

const manageInvoiceRoutes = [
  {
    path: "/manage-invoice",
    element: <ManageInvoiceList />,
  }
];

export default manageInvoiceRoutes;
