import { lazy } from "react";

const BankList = lazy(() => import("./bankList"));

const bankRoutes = [
  {
    path: "/banks",
    element: <BankList />,
  }
];

export default bankRoutes;
