const initialState = {
    lightingList: []  
  }
  
  const LightingReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_LIGHTING_SUCCESS": {             
        return {
          ...state,
          getLightingSuccess: true,
          getLightingList: action.payload.data,
        };
      }
      case "GET_LIGHTING_FAILURE": {
        return {
          ...state,
          getLightingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_LIGHTING": {
        return {
          ...state,
          getLightingSuccess: false,
          getLightingFailure: false,
          getLightingList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_LIGHTING_SUCCESS": {             
        return {
          ...state,
          createLightingSuccess: true,
          createLightingData: action.payload.data,
        };
      }
      case "CREATE_LIGHTING_FAILURE": {
        return {
          ...state,
          createLightingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_LIGHTING": {
        return {
          ...state,
          createLightingSuccess: false,
          createLightingFailure: false,
          createLightingData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_LIGHTING_SUCCESS": {             
        return {
          ...state,
          updateLightingSuccess: true,
          updateLightingData: action.payload.data,
        };
      }
      case "UPDATE_LIGHTING_FAILURE": {
        return {
          ...state,
          updateLightingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_LIGHTING": {
        return {
          ...state,
          updateLightingSuccess: false,
          updateLightingFailure: false,
          updateLightingData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default LightingReducer
  
  