const initialState = {
    encumbrancesList: []  
  }
  
  const EncumbrancesReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ENCUMBRANCES_SUCCESS": {             
        return {
          ...state,
          getEncumbrancesSuccess: true,
          getEncumbrancesList: action.payload.data,
        };
      }
      case "GET_ENCUMBRANCES_FAILURE": {
        return {
          ...state,
          getEncumbrancesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ENCUMBRANCES": {
        return {
          ...state,
          getEncumbrancesSuccess: false,
          getEncumbrancesFailure: false,
          getEncumbrancesList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ENCUMBRANCES_SUCCESS": {             
        return {
          ...state,
          createEncumbrancesSuccess: true,
          createEncumbrancesData: action.payload.data,
        };
      }
      case "CREATE_ENCUMBRANCES_FAILURE": {
        return {
          ...state,
          createEncumbrancesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ENCUMBRANCES": {
        return {
          ...state,
          createEncumbrancesSuccess: false,
          createEncumbrancesFailure: false,
          createEncumbrancesData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ENCUMBRANCES_SUCCESS": {             
        return {
          ...state,
          updateEncumbrancesSuccess: true,
          updateEncumbrancesData: action.payload.data,
        };
      }
      case "UPDATE_ENCUMBRANCES_FAILURE": {
        return {
          ...state,
          updateEncumbrancesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ENCUMBRANCES": {
        return {
          ...state,
          updateEncumbrancesSuccess: false,
          updateEncumbrancesFailure: false,
          updateEncumbrancesData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default EncumbrancesReducer
  
  