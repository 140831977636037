export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200

export const currency = 'S$'
export const buttonOk = 'OK'
/* export const baseURL = 'http://localhost:'
export const ports = {
    "home": "5952",
    "access": "5953",
    "document": "5954",
    "image": "5954",
    "payroll": "5952",
    "report": "5959"
}; */

export const baseURL = 'https://a-v-r.com:'
export const ports = {
    "home": "5952",
    "access": "5953",
    "document": "5854",
    "image": "5954",
    "payroll": "5852",
    "report": "5959"
};
export const imagePath = baseURL + ports.image + '/images/pic/'
export const documentPath = baseURL + ports.document + '/image/'
export const emailPath = baseURL + ports.document + '/mail/'
export const logoPath = baseURL + ports.document + '/logo/'
export const QRPath = baseURL + ports.document + '/qrpayimage/'
export const constructionPath = baseURL + ports.document + '/qrpayimage/'
export const onSiteImagePath = baseURL + ports.document + '/onsite/'
export const onDocumentPath = baseURL + ports.document + '/document/'
export const onInvoicePath = baseURL + ports.document + '/invoice/'
export const draftStatus = ['purchaseorder', 'goodsreceived', 'assembleorder', 'assembleorderreceipt', 'quotation', 'sales', 'delivery', 'invoice']
export const productKey = 'BT'
export const serviceStatusCode = {
    "backlog": 1,
    "toDo": 2,
    "inProgress": 3,
    "pendingPayment": 4,
    "complete": 5,
    "cancel": 6,
    "pickUp": 7,
    "delivery": 8,
    "close": 9,
    "review": 10,
    "verified": 11
}

export const currencySymbol = {
    ruppess: "₹",
    dollar: "$"
}

export const requestorNotifiedList = [
    {
        requestorNotifiedId: 1,
        requestorNotifiedName: "Yes"
    },
    {
        requestorNotifiedId: 2,
        requestorNotifiedName: "No"
    },
]

export const statusCode = {
    "uninspectedStatus": 1,
    "inspectedStatus": 2,
    "closedStatus": 3,
    "abortedStatus": 4,
}

export const documentType = {
    "inlisDoc": 1,
    "locationPlan": 2,
    "cadestralMap": 3,
    "sitePlan": 4,
    "landedCertificate": 5,
    "locationReport": 6,
    "stratCertificate": 7,
    "subjectDevelopment": 8,
    "termsAndCondition": 9,
    "others": 10
}