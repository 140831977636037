import { lazy } from "react";

const InspectedCaseList = lazy(() => import("./inspectedCaseList"));

const inspectedCaseRoutes = [
  {
    path: "/inspected-case",
    element: <InspectedCaseList />,
  }
];

export default inspectedCaseRoutes;
