const initialState = {
    uninspectedFilterCaseList: []  
  }
  
  const UninspectedFilterCaseReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_UNINSPECTED_FILTER_CASE_SUCCESS": {             
        return {
          ...state,
          getUninspectedFilterCaseSuccess: true,
          getUninspectedFilterCaseList: action.payload.data,
        };
      }
      case "GET_UNINSPECTED_FILTER_CASE_FAILURE": {
        return {
          ...state,
          getUninspectedFilterCaseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_UNINSPECTED_FILTER_CASE": {
        return {
          ...state,
          getUninspectedFilterCaseSuccess: false,
          getUninspectedFilterCaseFailure: false,
          getUninspectedFilterCaseList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_UNINSPECTED_FILTER_CASE_SUCCESS": {             
        return {
          ...state,
          createUninspectedFilterCaseSuccess: true,
          createUninspectedFilterCaseData: action.payload.data,
        };
      }
      case "CREATE_UNINSPECTED_FILTER_CASE_FAILURE": {
        return {
          ...state,
          createUninspectedFilterCaseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_UNINSPECTED_FILTER_CASE": {
        return {
          ...state,
          createUninspectedFilterCaseSuccess: false,
          createUninspectedFilterCaseFailure: false,
          createUninspectedFilterCaseData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_UNINSPECTED_FILTER_CASE_SUCCESS": {             
        return {
          ...state,
          updateUninspectedFilterCaseSuccess: true,
          updateUninspectedFilterCaseData: action.payload.data,
        };
      }
      case "UPDATE_UNINSPECTED_FILTER_CASE_FAILURE": {
        return {
          ...state,
          updateUninspectedFilterCaseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_UNINSPECTED_FILTER_CASE": {
        return {
          ...state,
          updateUninspectedFilterCaseSuccess: false,
          updateUninspectedFilterCaseFailure: false,
          updateUninspectedFilterCaseData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default UninspectedFilterCaseReducer
  
  