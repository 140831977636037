const initialState = {
    inlisList: []  
  }
  
  const InlisReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_INLIS_SUCCESS": {             
        return {
          ...state,
          getInlisSuccess: true,
          getInlisList: action.payload.data,
        };
      }
      case "GET_INLIS_FAILURE": {
        return {
          ...state,
          getInlisFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_INLIS": {
        return {
          ...state,
          getInlisSuccess: false,
          getInlisFailure: false,
          getInlisList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_INLIS_SUCCESS": {             
        return {
          ...state,
          createInlisSuccess: true,
          createInlisData: action.payload.data,
        };
      }
      case "CREATE_INLIS_FAILURE": {
        return {
          ...state,
          createInlisFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_INLIS": {
        return {
          ...state,
          createInlisSuccess: false,
          createInlisFailure: false,
          createInlisData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_INLIS_SUCCESS": {             
        return {
          ...state,
          updateInlisSuccess: true,
          updateInlisData: action.payload.data,
        };
      }
      case "UPDATE_INLIS_FAILURE": {
        return {
          ...state,
          updateInlisFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_INLIS": {
        return {
          ...state,
          updateInlisSuccess: false,
          updateInlisFailure: false,
          updateInlisData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default InlisReducer
  
  