const initialState = {
    wallList: []  
  }
  
  const WallReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_WALL_SUCCESS": {             
        return {
          ...state,
          getWallSuccess: true,
          getWallList: action.payload.data,
        };
      }
      case "GET_WALL_FAILURE": {
        return {
          ...state,
          getWallFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_WALL": {
        return {
          ...state,
          getWallSuccess: false,
          getWallFailure: false,
          getWallList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_WALL_SUCCESS": {             
        return {
          ...state,
          createWallSuccess: true,
          createWallData: action.payload.data,
        };
      }
      case "CREATE_WALL_FAILURE": {
        return {
          ...state,
          createWallFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_WALL": {
        return {
          ...state,
          createWallSuccess: false,
          createWallFailure: false,
          createWallData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_WALL_SUCCESS": {             
        return {
          ...state,
          updateWallSuccess: true,
          updateWallData: action.payload.data,
        };
      }
      case "UPDATE_WALL_FAILURE": {
        return {
          ...state,
          updateWallFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_WALL": {
        return {
          ...state,
          updateWallSuccess: false,
          updateWallFailure: false,
          updateWallData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default WallReducer
  
  