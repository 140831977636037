import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./redux/api/apiSlice";
import authSliceReducer from "./redux/auth/authSlice";
import layoutSliceReducer from "./redux/layout/layoutSlice";
import loginSliceReducer from "./redux/login/authSlice";
import ecommerceSliceReducer from "./redux/ecommerce/ecommerceSlice";
import PermissionReducer from "./redux/reducers/PermissionReducer";
import DashboardReducer from "./redux/reducers/DashboardReducer";
import AccomodationReducer from "./redux/reducers/AccomodationReducer";
import AirconReducer from "./redux/reducers/AirconReducer";
import CeilingReducer from "./redux/reducers/CeilingReducer";
import DoorsReducer from "./redux/reducers/DoorsReducer";
import FittingReducer from "./redux/reducers/FittingReducer";
import FloorReducer from "./redux/reducers/FloorReducer";
import PropertyTypeReducer from "./redux/reducers/PropertyTypeReducer";
import RoleReducer from "./redux/reducers/RoleReducer";
import TenancyReducer from "./redux/reducers/TenancyReducer";
import TimeSlotReducer from "./redux/reducers/TimeSlotReducer";
import WallReducer from "./redux/reducers/WallReducer";
import ZoningReducer from "./redux/reducers/ZoningReducer";
import WindowsReducer from "./redux/reducers/WindowsReducer";
import RequestorReducer from "./redux/reducers/RequestorReducer";
import UserReducer from "./redux/reducers/UserReducer";
import IndicativeValuationReducer from "./redux/reducers/IndicativeValuationReducer";
import UninspectedCaseReducer from "./redux/reducers/UnispectedCaseReducer";
import InspectedCaseReducer from "./redux/reducers/InspectedCaseReducer";
import TicketStatusLogReducer from "./redux/reducers/TicketStatusLogReducer";
import InvoiceSettingsReducer from "./redux/reducers/InvoiceSettingsReducer";
import DashboardServiceReducer from "./redux/reducers/DashBoardServiceReducer";
import TimeSlotDetailsReducer from "./redux/reducers/TimeSoltDetailsReducer";
import ManageInvoiceReducer from "./redux/reducers/ManageInvoiceReducer";
import SearchTypeReducer from "./redux/reducers/SearchTypeReducer";
import ValuationTypeReducer from "./redux/reducers/ValuationTypeReducer";
import DepartmentReducer from "./redux/reducers/DepartmentReducer";
import FacingReducer from "./redux/reducers/FacingReducer";
import UninspectedFilterCaseReducer from "./redux/reducers/UninspectedFilterCaseReducer";
import PurposeReducer from "./redux/reducers/PurposeReducer";
import EncumbrancesReducer from "./redux/reducers/EncumbrancesReducer";
import AbortCaseDetailsReducer from "./redux/reducers/AbortDetailsReducer";
import ZoneReducer from "./redux/reducers/ZoneReducer";
import ZonePincodeReducer from "./redux/reducers/ZonePincodeReducer";
import ReportTypeReducer from "./redux/reducers/ReportTypeReducer";
import InlisReducer from "./redux/reducers/InlisReducer";
import OrientationReducer from "./redux/reducers/OrientationReducer";
import ViewReducer from "./redux/reducers/ViewReducer";
import LegalDescriptionReducer from "./redux/reducers/LegalDescriptionReducer";
import ReasonReducer from "./redux/reducers/ReasonReducer";
import TicketDetailsReducer from "./redux/reducers/TicketDetailsReducer";
import DocumentReducer from "./redux/reducers/DocumentReducer";
import DocumentTypeReducer from "./redux/reducers/DocumentTypeReducer";
import CompanyBankSettingsReducer from "./redux/reducers/CompanyBankSettingsReducer";
import ReportSettingsReducer from "./redux/reducers/ReportSettingsReducer";
import CompanySettingsReducer from "./redux/reducers/CompanySettingsReducer";
import ReportTemplateReducer from "./redux/reducers/ReportTemplateReducer";
import ReportFieldsReducer from "./redux/reducers/ReportFieldsReducer";
import LightingReducer from "./redux/reducers/LightingReducer";
import SubReportTypeReducer from "./redux/reducers/SubReportTypeReducer";
import UploadLiReducer from "./redux/reducers/UploadLiReducer";
import PagesReducer from "./redux/reducers/PagesReducer";
import ServiceFacilitiesReducer from "./redux/reducers/ServiceFacilitiesReducer";
import StateOfRepairReducer from "./redux/reducers/StateOfRepairReducer";
import SignatoryReducer from "./redux/reducers/SignatoryReducer";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSliceReducer,
    layout: layoutSliceReducer,
    login: loginSliceReducer,
    ecommerce: ecommerceSliceReducer,
    permissionReducer: PermissionReducer,
    dashboardReducer: DashboardReducer,

    accomodationReducer: AccomodationReducer,
    airconReducer: AirconReducer,
    ceilingReducer:CeilingReducer,
    doorsReducer:DoorsReducer,
    fittingReducer: FittingReducer,
    floorReducer: FloorReducer,
    propertyTypeReducer: PropertyTypeReducer,
    roleReducer:RoleReducer,
    tenancyReducer:TenancyReducer,
    timeSlotReducer:TimeSlotReducer,
    wallReducer:WallReducer,
    zoningReducer:ZoningReducer,
    windowsReducer:WindowsReducer,
    requestorReducer:RequestorReducer,
    userReducer:UserReducer,
    indicativeValuationReducer: IndicativeValuationReducer,
    uninspectedCaseReducer: UninspectedCaseReducer,
    inspectedCaseReducer: InspectedCaseReducer,
    ticketStatusLogReducer: TicketStatusLogReducer,
    invoiceSettingsReducer: InvoiceSettingsReducer,
    dashBoardServiceReducer :DashboardServiceReducer,
    timeSlotDetailsReducer: TimeSlotDetailsReducer,
    manageInvoiceReducer: ManageInvoiceReducer,
    searchTypeReducer: SearchTypeReducer,
    valuationTypeReducer: ValuationTypeReducer,
    departmentReducer: DepartmentReducer,
    facingReducer:FacingReducer,
    uninspectedFilterCaseReducer:UninspectedFilterCaseReducer,
    purposeReducer:PurposeReducer,
    encumbrancesReducer:EncumbrancesReducer,
    abortCaseDetailsReducer: AbortCaseDetailsReducer,
    zoneReducer: ZoneReducer,
    zonePincodeReducer: ZonePincodeReducer,
    reportTypeReducer: ReportTypeReducer,
    inlisReducer: InlisReducer,
    orientationReducer: OrientationReducer,
    viewReducer: ViewReducer,
    legalDescriptionReducer: LegalDescriptionReducer,
    reasonReducer: ReasonReducer,
    ticketDetailsReducer: TicketDetailsReducer,
    documentReducer: DocumentReducer,
    documentTypeReducer: DocumentTypeReducer,
    companyBankSettingsReducer: CompanyBankSettingsReducer,
    reportSettingsReducer: ReportSettingsReducer,
    companySettingsReducer: CompanySettingsReducer,
    reportTemplateReducer: ReportTemplateReducer,
    reportFieldsReducer: ReportFieldsReducer,
    lightingReducer: LightingReducer,
    subReportTypeReducer: SubReportTypeReducer,
    uploadLiReducer: UploadLiReducer,
    pagesReducer: PagesReducer,
    serviceFacilitiesReducer: ServiceFacilitiesReducer,
    stateOfRepairReducer: StateOfRepairReducer,
    signatoryReducer: SignatoryReducer,
  },
  
  // RootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares({serializableCheck: false}).concat(apiSlice.middleware),
});
