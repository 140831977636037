import { lazy } from "react";

const HeaderSettingsList = lazy(() => import("./headerSettingsList"));

const headerSettingsRoutes = [
  {
    path: "/header-settings",
    element: <HeaderSettingsList />,
  }
];

export default headerSettingsRoutes;
