
const spLoginAPI = "access-management/sp-login";
const dashboard= "home-management/dashboard"
const permission = "access-management/permission"
const serviceProviderDetails = "access-management/service-provider-details";

const dashboardReport ="/dashboard-report"
const dashboardService= "/dashboard-service"
const login = "/login"
const resetPasswordApi = "/reset-password"
const abortedCase="/aborted-case"
const accomodation="/accomodation"
const aircon="/aircon"
const ceiling="/ceiling"
const doors= "/doors"
const fitting= "/fitting"
const floor= "/floor"
const indicativeValuation= "/indicative-valuation"
const inspectedCase= "/inspected-case"
const invoiceSettings= "/invoice-settings"
const propertyType = "/property-type"
const requestor = "/requestor"
const bank = "/bank"
const role = "/role"
const tenancy = "/tenancy"
const ticketStatusLog = "/ticket-status-log"
const timeSlot = "/time-slot"
const timeSlotDetails = "/time-slot-details"
const uninspectedCase = "/uninspected-case"
const uninspectedInlis = "/uninspected-inlis"
const valuer = "/valuer"
const inspector = "/inspector"
const user = "/user"
const wall = "/wall"
const zoning = "/zoning"
const windows = "/windows"
const manageInvoice = "/manage-invoice"
const searchType = "/search-type"
const valuationType = "/valuation-type"
const department = "/departments"
const facing = "/facing"
const purpose = "/purpose"
const encumbrances = "/encumbrances"
const lighting = "/lighting"
const abortedCaseDetails = "/abort-case-details"
const zone = "/zone"
const zonePincode = "/zone-pincode"
const reportType = "/report-type-data"
const reportTemplateWithRequestor = "/report-template-requestor"
const inlis = "/inlis"
const cases = "/case"
const subReportType = "/sub-report-type"
const orientation = "/orientation"
const view = "/view"
const legalDescription = "/legal-description"
const reportSettings = "/report-settings"
const construction = "/construction"
const reason = "/reason"
const document = "/document-management/ticket-document"
const documentType = "/document-management/document-type"
const requestorNotifiedEmail = "/document-management/requestor-notified-email"
const onsiteImageUpload = "/document-management/onsite-images"
const QrUpload = "/document-management/qr-image"
const logoUpload = "/document-management/upload-logo"
const companyBankSettings = "/company-bank-settings"
const companySettings = "/company-settings"
const reportTemplate = "/report-template"
const reportTemplateDetails = "/report-template-details"
const reportFields = "/report-fields"
const pages = "/pages"
const serviceFacilities = "/service-facilities"
const rolePermission = "/role-permission"
const reportTypeData = "/report-type-data"
const propertyinfoData = "/avm/propertyinfo"
const signatoryDocument = "/document-management/signatory-document"
const invoiceDocument = "/document-management/invoice-document"
const uploadLi = "https://uat.simplyflow.ai/backend/api/upload-rule-extraction/"
const getUploadLiApi = "https://uat.simplyflow.ai/backend/api/upload-rule-extraction/{unique_id}/"
const comparablesApi = "https://century99avm.com/api/property/propertyinfo?postal_code=119028"
const comparables = "/comparables"
const stateOfRepair= "/state-repair"
const constructionFinishesApi = "/construction-finishes"
const utilityApi = "/offline-data"

export { 
    windows,
    documentType,
    serviceFacilities,
    rolePermission,
    reportTemplateDetails,
    reportTypeData,
    propertyinfoData,
    legalDescription,
    signatoryDocument,
    invoiceDocument,
    uploadLi,
    getUploadLiApi,
    reportFields,
    reportTemplate,
    logoUpload,
    companySettings,
    pages,
    QrUpload,
    onsiteImageUpload,
    companyBankSettings,
    subReportType,
    reason,
    reportTemplateWithRequestor,
    lighting,
    construction,
    requestorNotifiedEmail,
    reportSettings,
    document,
    view,
    zonePincode,
    reportType,
    requestor,
    bank,
    orientation,
    zone,
    floor,
    cases,
    aircon,
    inlis,
    doors,
    invoiceSettings,
    propertyType,
    ceiling,
    fitting,
    accomodation,
    abortedCase,
    dashboardReport,
    abortedCaseDetails,
    indicativeValuation,
    tenancy,
    inspectedCase,
    role,
    timeSlot,
    ticketStatusLog,
    wall,
    zoning,
    uninspectedCase,
    uninspectedInlis,
    timeSlotDetails,
    valuer,
    inspector,
    user,
    spLoginAPI,
    dashboardService,
    permission,
    dashboard,
    serviceProviderDetails,
    login,
    resetPasswordApi,
    facing,
    department,
    manageInvoice,
    valuationType,
    searchType,
    purpose,
    encumbrances,
    stateOfRepair,
    constructionFinishesApi,
    utilityApi,
    comparablesApi,
    comparables
}
