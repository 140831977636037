import { lazy } from "react";

const Signatory = lazy(() => import("./signatoryList"));

const signatoryRoutes = [
  {
    path: "/signatory",
    element: <Signatory />,
  }
];

export default signatoryRoutes;
