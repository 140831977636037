import { lazy } from "react";

const CompanyBankSettingsList = lazy(() => import("./companyBankSettingsList"));

const companyBankSettingsRoutes = [
  {
    path: "/company-bank-settings",
    element: <CompanyBankSettingsList />,
  }
];

export default companyBankSettingsRoutes;
