import { lazy } from "react";

const ZonePincodeList = lazy(() => import("./zonePincodeList"));

const zonePincodeRoutes = [
  {
    path: "/zone",
    element: <ZonePincodeList />,
  }
];

export default zonePincodeRoutes;
