const initialState = {
    facingList: []  
  }
  
  const FacingReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_FACING_SUCCESS": {             
        return {
          ...state,
          getFacingSuccess: true,
          getFacingList: action.payload.data,
        };
      }
      case "GET_FACING_FAILURE": {
        return {
          ...state,
          getFacingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_FACING": {
        return {
          ...state,
          getFacingSuccess: false,
          getFacingFailure: false,
          getFacingList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_FACING_SUCCESS": {             
        return {
          ...state,
          createFacingSuccess: true,
          createFacingData: action.payload.data,
        };
      }
      case "CREATE_FACING_FAILURE": {
        return {
          ...state,
          createFacingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_FACING": {
        return {
          ...state,
          createFacingSuccess: false,
          createFacingFailure: false,
          createFacingData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_FACING_SUCCESS": {             
        return {
          ...state,
          updateFacingSuccess: true,
          updateFacingData: action.payload.data,
        };
      }
      case "UPDATE_FACING_FAILURE": {
        return {
          ...state,
          updateFacingFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_FACING": {
        return {
          ...state,
          updateFacingSuccess: false,
          updateFacingFailure: false,
          updateFacingData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default FacingReducer
  
  