const initialState = {
    reportSettingsList: []  
  }
  
  const ReportSettingsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_REPORT_SETTINGS_SUCCESS": {             
        return {
          ...state,
          getReportSettingsSuccess: true,
          getReportSettingsList: action.payload.data,
        };
      }
      case "GET_REPORT_SETTINGS_FAILURE": {
        return {
          ...state,
          getReportSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REPORT_SETTINGS": {
        return {
          ...state,
          getReportSettingsSuccess: false,
          getReportSettingsFailure: false,
          getReportSettingsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_REPORT_SETTINGS_SUCCESS": {             
        return {
          ...state,
          createReportSettingsSuccess: true,
          createReportSettingsData: action.payload.data,
        };
      }
      case "CREATE_REPORT_SETTINGS_FAILURE": {
        return {
          ...state,
          createReportSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_REPORT_SETTINGS": {
        return {
          ...state,
          createReportSettingsSuccess: false,
          createReportSettingsFailure: false,
          createReportSettingsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_REPORT_SETTINGS_SUCCESS": {             
        return {
          ...state,
          updateReportSettingsSuccess: true,
          updateReportSettingsData: action.payload.data,
        };
      }
      case "UPDATE_REPORT_SETTINGS_FAILURE": {
        return {
          ...state,
          updateReportSettingsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_REPORT_SETTINGS": {
        return {
          ...state,
          updateReportSettingsSuccess: false,
          updateReportSettingsFailure: false,
          updateReportSettingsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ReportSettingsReducer
  
  