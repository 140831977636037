import { lazy } from "react";

const EncumbrancesList = lazy(() => import("./encumbrancesList"));

const encumbrancesRoutes = [
  {
    path: "/encumbrances",
    element: <EncumbrancesList />,
  }
];

export default encumbrancesRoutes;


