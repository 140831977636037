const initialState = {
    reportTemplateList: []  
  }
  
  const ReportTemplateReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_REPORT_TEMPLATE_SUCCESS": {             
        return {
          ...state,
          getReportTemplateSuccess: true,
          getReportTemplateList: action.payload.data,
        };
      }
      case "GET_REPORT_TEMPLATE_FAILURE": {
        return {
          ...state,
          getReportTemplateFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REPORT_TEMPLATE": {
        return {
          ...state,
          getReportTemplateSuccess: false,
          getReportTemplateFailure: false,
          getReportTemplateList: [],
          errorMessage: false
        };
      }

      case "GET_REPORT_TEMPLATE_REQUESTOR_SUCCESS": {             
        return {
          ...state,
          getReportTemplateRequestorSuccess: true,
          getReportTemplateRequestorList: action.payload.data,
        };
      }
      case "GET_REPORT_TEMPLATE_REQUESTOR_FAILURE": {
        return {
          ...state,
          getReportTemplateRequestorFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REPORT_TEMPLATE_REQUESTOR": {
        return {
          ...state,
          getReportTemplateRequestorSuccess: false,
          getReportTemplateRequestorFailure: false,
          getReportTemplateRequestorList: [],
          errorMessage: false
        };
      }

      case "GET_REPORT_TEMPLATE_DETAILS_SUCCESS": {             
        return {
          ...state,
          getReportTemplateDetailsSuccess: true,
          getReportTemplateDetailsList: action.payload.data,
        };
      }
      case "GET_REPORT_TEMPLATE_DETAILS_FAILURE": {
        return {
          ...state,
          getReportTemplateDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REPORT_DETAILS_TEMPLATE": {
        return {
          ...state,
          getReportTemplateDetailsSuccess: false,
          getReportTemplateDetailsFailure: false,
          getReportTemplateDetailsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_REPORT_TEMPLATE_SUCCESS": {             
        return {
          ...state,
          createReportTemplateSuccess: true,
          createReportTemplateData: action.payload.data,
        };
      }
      case "CREATE_REPORT_TEMPLATE_FAILURE": {
        return {
          ...state,
          createReportTemplateFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_REPORT_TEMPLATE": {
        return {
          ...state,
          createReportTemplateSuccess: false,
          createReportTemplateFailure: false,
          createReportTemplateData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_REPORT_TEMPLATE_SUCCESS": {             
        return {
          ...state,
          updateReportTemplateSuccess: true,
          updateReportTemplateData: action.payload.data,
        };
      }
      case "UPDATE_REPORT_TEMPLATE_FAILURE": {
        return {
          ...state,
          updateReportTemplateFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_REPORT_TEMPLATE": {
        return {
          ...state,
          updateReportTemplateSuccess: false,
          updateReportTemplateFailure: false,
          updateReportTemplateData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ReportTemplateReducer
  
  