import { lazy } from "react";

const WallList = lazy(() => import("./wallList"));

const wallRoutes = [
  {
    path: "/wall",
    element: <WallList />,
  }
];

export default wallRoutes;
