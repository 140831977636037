const initialState = {
    zonePincodeList: []  
  }
  
  const ZonePincodeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ZONE_PINCODE_SUCCESS": {             
        return {
          ...state,
          getZonePincodeSuccess: true,
          getZonePincodeList: action.payload.data,
        };
      }
      case "GET_ZONE_PINCODE_FAILURE": {
        return {
          ...state,
          getZonePincodeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ZONE_PINCODE": {
        return {
          ...state,
          getZonePincodeSuccess: false,
          getZonePincodeFailure: false,
          getZonePincodeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ZONE_PINCODE_SUCCESS": {             
        return {
          ...state,
          createZonePincodeSuccess: true,
          createZonePincodeData: action.payload.data,
        };
      }
      case "CREATE_ZONE_PINCODE_FAILURE": {
        return {
          ...state,
          createZonePincodeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ZONE_PINCODE": {
        return {
          ...state,
          createZonePincodeSuccess: false,
          createZonePincodeFailure: false,
          createZonePincodeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ZONE_PINCODE_SUCCESS": {             
        return {
          ...state,
          updateZonePincodeSuccess: true,
          updateZonePincodeData: action.payload.data,
        };
      }
      case "UPDATE_ZONE_PINCODE_FAILURE": {
        return {
          ...state,
          updateZonePincodeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ZONE_PINCODE": {
        return {
          ...state,
          updateZonePincodeSuccess: false,
          updateZonePincodeFailure: false,
          updateZonePincodeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ZonePincodeReducer
  
  