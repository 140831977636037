const initialState = {
    timeSlotList: []  
  }
  
  const TimeSlotReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_TIME_SLOT_SUCCESS": {             
        return {
          ...state,
          getTimeSlotSuccess: true,
          getTimeSlotList: action.payload.data,
        };
      }
      case "GET_TIME_SLOT_FAILURE": {
        return {
          ...state,
          getTimeSlotFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TIME_SLOT": {
        return {
          ...state,
          getTimeSlotSuccess: false,
          getTimeSlotFailure: false,
          getTimeSlotList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_TIME_SLOT_SUCCESS": {             
        return {
          ...state,
          createTimeSlotSuccess: true,
          createTimeSlotData: action.payload.data,
        };
      }
      case "CREATE_TIME_SLOT_FAILURE": {
        return {
          ...state,
          createTimeSlotFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_TIME_SLOT": {
        return {
          ...state,
          createTimeSlotSuccess: false,
          createTimeSlotFailure: false,
          createTimeSlotData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_TIME_SLOT_SUCCESS": {             
        return {
          ...state,
          updateTimeSlotSuccess: true,
          updateTimeSlotData: action.payload.data,
        };
      }
      case "UPDATE_TIME_SLOT_FAILURE": {
        return {
          ...state,
          updateTimeSlotFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_TIME_SLOT": {
        return {
          ...state,
          updateTimeSlotSuccess: false,
          updateTimeSlotFailure: false,
          updateTimeSlotData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default TimeSlotReducer
  
  