const initialState = {
    purposeList: []  
  }
  
  const PurposeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_PURPOSE_SUCCESS": {             
        return {
          ...state,
          getPurposeSuccess: true,
          getPurposeList: action.payload.data,
        };
      }
      case "GET_PURPOSE_FAILURE": {
        return {
          ...state,
          getPurposeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_PURPOSE": {
        return {
          ...state,
          getPurposeSuccess: false,
          getPurposeFailure: false,
          getPurposeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_PURPOSE_SUCCESS": {             
        return {
          ...state,
          createPurposeSuccess: true,
          createPurposeData: action.payload.data,
        };
      }
      case "CREATE_PURPOSE_FAILURE": {
        return {
          ...state,
          createPurposeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_PURPOSE": {
        return {
          ...state,
          createPurposeSuccess: false,
          createPurposeFailure: false,
          createPurposeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_PURPOSE_SUCCESS": {             
        return {
          ...state,
          updatePurposeSuccess: true,
          updatePurposeData: action.payload.data,
        };
      }
      case "UPDATE_PURPOSE_FAILURE": {
        return {
          ...state,
          updatePurposeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_PURPOSE": {
        return {
          ...state,
          updatePurposeSuccess: false,
          updatePurposeFailure: false,
          updatePurposeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default PurposeReducer
  
  