const initialState = {
    legalDescriptionList: []  
  }
  
  const LegalDescriptionReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_LEGAL_DESCRIPTION_SUCCESS": {             
        return {
          ...state,
          getLegalDescriptionSuccess: true,
          getLegalDescriptionList: action.payload.data,
        };
      }
      case "GET_LEGAL_DESCRIPTION_FAILURE": {
        return {
          ...state,
          getLegalDescriptionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_LEGAL_DESCRIPTION": {
        return {
          ...state,
          getLegalDescriptionSuccess: false,
          getLegalDescriptionFailure: false,
          getLegalDescriptionList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_LEGAL_DESCRIPTION_SUCCESS": {             
        return {
          ...state,
          createLegalDescriptionSuccess: true,
          createLegalDescriptionData: action.payload.data,
        };
      }
      case "CREATE_LEGAL_DESCRIPTION_FAILURE": {
        return {
          ...state,
          createLegalDescriptionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_LEGAL_DESCRIPTION": {
        return {
          ...state,
          createLegalDescriptionSuccess: false,
          createLegalDescriptionFailure: false,
          createLegalDescriptionData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_LEGAL_DESCRIPTION_SUCCESS": {             
        return {
          ...state,
          updateLegalDescriptionSuccess: true,
          updateLegalDescriptionData: action.payload.data,
        };
      }
      case "UPDATE_LEGAL_DESCRIPTION_FAILURE": {
        return {
          ...state,
          updateLegalDescriptionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_LEGAL_DESCRIPTION": {
        return {
          ...state,
          updateLegalDescriptionSuccess: false,
          updateLegalDescriptionFailure: false,
          updateLegalDescriptionData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default LegalDescriptionReducer
  
  