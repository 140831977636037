const initialState = {
    inspectedCaseList: []  
  }
  
  const InspectedCaseReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_INSPECTED_CASE_SUCCESS": {             
        return {
          ...state,
          getInspectedCaseSuccess: true,
          getInspectedCaseList: action.payload.data,
        };
      }
      case "GET_INSPECTED_CASE_FAILURE": {
        return {
          ...state,
          getInspectedCaseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_INSPECTED_CASE": {
        return {
          ...state,
          getInspectedCaseSuccess: false,
          getInspectedCaseFailure: false,
          getInspectedCaseList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_INSPECTED_CASE_SUCCESS": {             
        return {
          ...state,
          createInspectedCaseSuccess: true,
          createInspectedCaseData: action.payload.data,
        };
      }
      case "CREATE_INSPECTED_CASE_FAILURE": {
        return {
          ...state,
          createInspectedCaseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_INSPECTED_CASE": {
        return {
          ...state,
          createInspectedCaseSuccess: false,
          createInspectedCaseFailure: false,
          createInspectedCaseData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_INSPECTED_CASE_SUCCESS": {             
        return {
          ...state,
          updateInspectedCaseSuccess: true,
          updateInspectedCaseData: action.payload.data,
        };
      }
      case "UPDATE_INSPECTED_CASE_FAILURE": {
        return {
          ...state,
          updateInspectedCaseFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_INSPECTED_CASE": {
        return {
          ...state,
          updateInspectedCaseSuccess: false,
          updateInspectedCaseFailure: false,
          updateInspectedCaseData: false,
          errorMessage: false         
        };
      }

      case "DELETE_CONSTRUCTION_SUCCESS": {             
        return {
          ...state,
          deleteConstructionSuccess: true,
        };
      }
      case "DELETE_CONSTRUCTION_FAILURE": {
        return {
          ...state,
          deleteConstructionFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_DELETE_CONSTRUCTION": {
        return {
          ...state,
          deleteConstructionSuccess: false,
          deleteConstructionFailure: false,
          errorMessage: false         
        };
      }

      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default InspectedCaseReducer
  
  