const initialState = {
    tenancyList: []
}

const StateOfRepairReducer = function (state = initialState, action) {
    switch (action.type) {
        case "GET_STATE_REPAIR_SUCCESS": {
            return {
                ...state,
                getStateRepairSuccess: true,
                getStateRepairList: action.payload.data,
            };
        }
        case "GET_STATE_REPAIR_FAILURE": {
            return {
                ...state,
                getStateRepairFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_STATE_REPAIR": {
            return {
                ...state,
                getStateRepairSuccess: false,
                getStateRepairFailure: false,
                getStateRepairList: [],
                errorMessage: false
            };
        }

        case "CREATE_STATE_REPAIR_SUCCESS": {
            return {
                ...state,
                createStateRepairSuccess: true,
                createStateRepairData: action.payload.data,
            };
        }
        case "CREATE_STATE_REPAIR_FAILURE": {
            return {
                ...state,
                createStateRepairFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_STATE_REPAIR": {
            return {
                ...state,
                createStateRepairSuccess: false,
                createStateRepairFailure: false,
                createStateRepairData: false,
                errorMessage: false
            };
        }

        case "UPDATE_STATE_REPAIR_SUCCESS": {
            return {
                ...state,
                updateStateRepairSuccess: true,
                updateStateRepairData: action.payload.data,
            };
        }
        case "UPDATE_STATE_REPAIR_FAILURE": {
            return {
                ...state,
                updateStateRepairFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_UPDATE_STATE_REPAIR": {
            return {
                ...state,
                updateStateRepairSuccess: false,
                updateStateRepairFailure: false,
                updateStateRepairData: false,
                errorMessage: false
            };
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default StateOfRepairReducer

