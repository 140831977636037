import { lazy } from "react";

const SubReportTypeList = lazy(() => import("./subReportTypeList"));

const subReportTypeRoutes = [
  {
    path: "/sub-report-type",
    element: <SubReportTypeList />,
  }
];

export default subReportTypeRoutes;
