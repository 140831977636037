const initialState = {
    zoningList: []
}

const ZoningReducer = function (state = initialState, action) {
    switch (action.type) {
        case "GET_ZONING_SUCCESS": {
            return {
                ...state,
                getZoningSuccess: true,
                getZoningList: action.payload.data,
            };
        }
        case "GET_ZONING_FAILURE": {
            return {
                ...state,
                getZoningFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_GET_ZONING": {
            return {
                ...state,
                getZoningSuccess: false,
                getZoningFailure: false,
                getZoningList: [],
                errorMessage: false
            };
        }

        case "CREATE_ZONING_SUCCESS": {
            return {
                ...state,
                createZoningSuccess: true,
                createZoningData: action.payload.data,
            };
        }
        case "CREATE_ZONING_FAILURE": {
            return {
                ...state,
                createZoningFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_CREATE_ZONING": {
            return {
                ...state,
                createZoningSuccess: false,
                createZoningFailure: false,
                createZoningData: false,
                errorMessage: false
            };
        }

        case "UPDATE_ZONING_SUCCESS": {
            return {
                ...state,
                updateZoningSuccess: true,
                updateZoningData: action.payload.data,
            };
        }
        case "UPDATE_ZONING_FAILURE": {
            return {
                ...state,
                updateZoningFailure: true,
                errorMessage: action.errorMessage
            };
        }
        case "RESET_UPDATE_ZONING": {
            return {
                ...state,
                updateZoningSuccess: false,
                updateZoningFailure: false,
                updateZoningData: false,
                errorMessage: false
            };
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default ZoningReducer

