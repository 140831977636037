import { lazy } from "react";

const WindowsList = lazy(() => import("./windowsList"));

const windowsRoutes = [
  {
    path: "/windows",
    element: <WindowsList />,
  }
];

export default windowsRoutes;
