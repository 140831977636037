const initialState = {
    accomodationList: []  
  }
  
  const AccomodationReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ACCOMODATION_SUCCESS": {             
        return {
          ...state,
          getAccomodationSuccess: true,
          getAccomodationList: action.payload.data,
        };
      }
      case "GET_ACCOMODATION_FAILURE": {
        return {
          ...state,
          getAccomodationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ACCOMODATION": {
        return {
          ...state,
          getAccomodationSuccess: false,
          getAccomodationFailure: false,
          getAccomodationList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ACCOMODATION_SUCCESS": {             
        return {
          ...state,
          createAccomodationSuccess: true,
          createAccomodationData: action.payload.data,
        };
      }
      case "CREATE_ACCOMODATION_FAILURE": {
        return {
          ...state,
          createAccomodationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ACCOMODATION": {
        return {
          ...state,
          createAccomodationSuccess: false,
          createAccomodationFailure: false,
          createAccomodationData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ACCOMODATION_SUCCESS": {             
        return {
          ...state,
          updateAccomodationSuccess: true,
          updateAccomodationData: action.payload.data,
        };
      }
      case "UPDATE_ACCOMODATION_FAILURE": {
        return {
          ...state,
          updateAccomodationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ACCOMODATION": {
        return {
          ...state,
          updateAccomodationSuccess: false,
          updateAccomodationFailure: false,
          updateAccomodationData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default AccomodationReducer
  
  