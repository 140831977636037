import { lazy } from "react";

const CeilingList = lazy(() => import("./ceilingList"));

const ceilingRoutes = [
  {
    path: "/ceiling",
    element: <CeilingList />,
  }
];

export default ceilingRoutes;
