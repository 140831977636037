import React, { createContext, useEffect, useReducer, useState } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { Navigate, redirect, useLocation, useNavigate } from 'react-router-dom'
// import { MatxLoading } from 'app/components'
import { UserLogin, userDetailsInfo } from '../api/LoginApi'
import AuthGuard from 'app/auth/AuthGuard'
import { showMessage } from 'app/utils/app-functions'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 100
    return decodedToken.exp > currentTime
}

const setSession_org = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const setSession = (accessToken, userInfo) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else if (userInfo) {
        // alert(userInfo)
        global.UserInfo = userInfo[0]
        localStorage.setItem('userInfo', JSON.stringify(global.UserInfo))
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userInfo')
        localStorage.removeItem('route-list')
        // localStorage.removeItem('branchInfo')
        localStorage.removeItem('user')
        localStorage.removeItem('page')
        // global.pageItems = [];
        global.pageItems = [{
            label: 'PAGES',
            type: 'label',
        }];
        // console.log('global.pageItems', JSON.stringify(global.pageItems))
        delete axios.defaults.headers.common.Authorization
    }
}

const setUserSession = (userData) => {
    if (userData) {
        localStorage.setItem('user', userData)
    } else {
        localStorage.removeItem('user')
    }
}

const setPageSession = (userData) => {
    if (userData) {
        localStorage.setItem('page', userData)
    } else {
        localStorage.removeItem('page')
    }
}

const getSession = (sessionName) => {
    return sessionName ? window.localStorage.getItem(sessionName) : false
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState)
    // const [previouseRoute, setPreviousRoute] = useState(null)
    // const { pathname } = useLocation()

    const userInfo = getSession("userInfo")
    global.UserInfo = userInfo ? JSON.parse(userInfo) : false;
    
    const login = async (email, password) => {
        /* const loginCredential = await axios.post('/api/auth/login', {
            email,
            password,
        }) */
        const loginCredential = {
            email: email,
            password: password
        }

        const response = await UserLogin(loginCredential)
        const { accessToken, loginSuccess, payload, errorMessage } = response
        // console.log("loginSuccess--->" + loginSuccess + "payload====>" + payload + "errorMessage====>" + errorMessage)
        /* dispatch({
            type: 'LOGIN',
            payload: {
                user: payload,
            },
        }) */
        if (loginSuccess) {            
            navigate('/')
            setSession(global.userId)
            setSession(false, payload.data);
            //const userDetailsInfos = await userDetailsInfo(loginCredential);
            // const { pages, userInfoSuccess, payload, errorMessage } = userDetailsInfos;
            /* if (userInfoSuccess) {
                setSession(false, payload);
              //  alert(JSON.stringify(pages))
                setPageSession(JSON.stringify(pages))
                global.pageItems = pages;
                navigate('/')
            } else {
                showMessage("error", errorMessage)
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user: {
                            id: "",
                            email: "",
                            name: "",
                            role: "",
                        },
                    },
                })
            } */

            // Navigate("/dashboard")

            // global.OrgID = orgId;            
            /* const userInfo = await GetUserInfo({ userId: userId })
            if (userInfo.data.length) {

                const userDetails = userInfo.data[0];
                setSession(accessToken)
                setSession(false, userInfo)
                
                global.pageItems.concat(userInfo.data[0].pages);

                global.pageItems = global.pageItems.concat(userInfo.data[0].pages);

                let userData = {
                    id: userId,
                    email: userDetails.email,
                    name: userDetails.firstName + " " + userDetails.lastName,
                    role: userDetails.roleId,
                    userCode: userDetails.identity
                }
                global.userId = userData?.id
                global.usercode = userData.userCode;
                setUserSession(JSON.stringify(userData))

                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user: userData,
                    },
                })
            } */

            /* dispatch({
                type: 'LOGIN',
                payload: {
                    user: {
                        id: 3,
                        email: "test@gmaik.com",
                        name: "saadsads",
                        role: "asdasd",
                    },
                },
            }) */

        } else {
            showMessage("error", errorMessage)
            dispatch({
                type: 'LOGIN',
                payload: {
                    user: {
                        id: "",
                        email: "",
                        name: "",
                        role: "",
                    },
                },
            })
        }

        /* const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        }) */
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null);
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                // alert(accessToken)
                // if (accessToken && isValidToken(accessToken)) {
                if (accessToken) {
                    setSession(accessToken)
                    /* const response = await axios.get('/api/auth/profile')
                    const { user } = response.data */
                    const userData = getSession('user');
                    const user = userData ? JSON.parse(userData) : false
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    /* if (!state.isInitialised) {
        return <MatxLoading />
    } */

    /* useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute]) */

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}>
            {/* <Navigate
                to="/user"                
                state={{ redirectUrl: previouseRoute }}
            /> */}

            {/* {
                (!state.initialState && state.isAuthenticated) ? (
                    <AuthGuard userInfo={{ ...state }} >
                        {children}
                    </AuthGuard>
                ) : children
            } */}

            {/* <AuthGuard userInfo={{ ...state }} >
                {children}
            </AuthGuard> */}

            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
