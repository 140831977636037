import { lazy } from "react";

const CompanySettingsList = lazy(() => import("./companySettingsList"));
const LocationSubject = lazy(() => import("./locationSubject"));
const ConstructionFinishes = lazy(() => import("./constructionFinishes"));

const companySettingsRoutes = [
  {
    path: "/company-settings",
    element: <CompanySettingsList />,
  },
  {
    path: "/construction-finishes",
    element: <ConstructionFinishes />,
  },
  {
    path: "/location-subject",
    element: <LocationSubject />,
  }
];

export default companySettingsRoutes;
