const initialState = {
    searchTypeList: []  
  }
  
  const SearchTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SEARCH_TYPE_SUCCESS": {             
        return {
          ...state,
          getSearchTypeSuccess: true,
          getSearchTypeList: action.payload.data,
        };
      }
      case "GET_SEARCH_TYPE_FAILURE": {
        return {
          ...state,
          getSearchTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SEARCH_TYPE": {
        return {
          ...state,
          getSearchTypeSuccess: false,
          getSearchTypeFailure: false,
          getSearchTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SEARCH_TYPE_SUCCESS": {             
        return {
          ...state,
          createSearchTypeSuccess: true,
          createSearchTypeData: action.payload.data,
        };
      }
      case "CREATE_SEARCH_TYPE_FAILURE": {
        return {
          ...state,
          createSearchTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SEARCH_TYPE": {
        return {
          ...state,
          createSearchTypeSuccess: false,
          createSearchTypeFailure: false,
          createSearchTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SEARCH_TYPE_SUCCESS": {             
        return {
          ...state,
          updateSearchTypeSuccess: true,
          updateSearchTypeData: action.payload.data,
        };
      }
      case "UPDATE_SEARCH_TYPE_FAILURE": {
        return {
          ...state,
          updateSearchTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SEARCH_TYPE": {
        return {
          ...state,
          updateSearchTypeSuccess: false,
          updateSearchTypeFailure: false,
          updateSearchTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default SearchTypeReducer
  
  