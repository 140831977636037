const initialState = {
    manageInvoiceList: []  
  }
  
  const ManageInvoiceReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_MANAGE_INVOICE_SUCCESS": {             
        return {
          ...state,
          getManageInvoiceSuccess: true,
          getManageInvoiceList: action.payload.data,
        };
      }
      case "GET_MANAGE_INVOICE_FAILURE": {
        return {
          ...state,
          getManageInvoiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_MANAGE_INVOICE": {
        return {
          ...state,
          getManageInvoiceSuccess: false,
          getManageInvoiceFailure: false,
          getManageInvoiceList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_MANAGE_INVOICE_SUCCESS": {             
        return {
          ...state,
          createManageInvoiceSuccess: true,
          createManageInvoiceData: action.payload.data,
        };
      }
      case "CREATE_MANAGE_INVOICE_FAILURE": {
        return {
          ...state,
          createManageInvoiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_MANAGE_INVOICE": {
        return {
          ...state,
          createManageInvoiceSuccess: false,
          createManageInvoiceFailure: false,
          createManageInvoiceData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_MANAGE_INVOICE_SUCCESS": {             
        return {
          ...state,
          updateManageInvoiceSuccess: true,
          updateManageInvoiceData: action.payload.data,
        };
      }
      case "UPDATE_MANAGE_INVOICE_FAILURE": {
        return {
          ...state,
          updateManageInvoiceFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_MANAGE_INVOICE": {
        return {
          ...state,
          updateManageInvoiceSuccess: false,
          updateManageInvoiceFailure: false,
          updateManageInvoiceData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ManageInvoiceReducer
  
  