const initialState = {
    abortCaseDetailsList: []  
  }
  
  const AbortCaseDetailsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ABORT_CASE_DETAILS_SUCCESS": {             
        return {
          ...state,
          getAbortCaseDetailsSuccess: true,
          getAbortCaseDetailsList: action.payload.data,
        };
      }
      case "GET_ABORT_CASE_DETAILS_FAILURE": {
        return {
          ...state,
          getAbortCaseDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ABORT_CASE_DETAILS": {
        return {
          ...state,
          getAbortCaseDetailsSuccess: false,
          getAbortCaseDetailsFailure: false,
          getAbortCaseDetailsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ABORT_CASE_DETAILS_SUCCESS": {             
        return {
          ...state,
          createAbortCaseDetailsSuccess: true,
          createAbortCaseDetailsData: action.payload.data,
        };
      }
      case "CREATE_ABORT_CASE_DETAILS_FAILURE": {
        return {
          ...state,
          createAbortCaseDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ABORT_CASE_DETAILS": {
        return {
          ...state,
          createAbortCaseDetailsSuccess: false,
          createAbortCaseDetailsFailure: false,
          createAbortCaseDetailsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ABORT_CASE_DETAILS_SUCCESS": {             
        return {
          ...state,
          updateAbortCaseDetailsSuccess: true,
          updateAbortCaseDetailsData: action.payload.data,
        };
      }
      case "UPDATE_ABORT_CASE_DETAILS_FAILURE": {
        return {
          ...state,
          updateAbortCaseDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ABORT_CASE_DETAILS": {
        return {
          ...state,
          updateAbortCaseDetailsSuccess: false,
          updateAbortCaseDetailsFailure: false,
          updateAbortCaseDetailsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default AbortCaseDetailsReducer