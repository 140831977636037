import { lazy } from "react";

const QCReport = lazy(() => import("./QCReportList"));

const qcReportRoutes = [
  {
    path: "/qc-report",
    element: <QCReport />,
  }
];

export default qcReportRoutes;
