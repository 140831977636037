import { lazy } from "react";

const DoorsList = lazy(() => import("./doorsList"));

const doorsRoutes = [
  {
    path: "/doors",
    element: <DoorsList />,
  }
];

export default doorsRoutes;
