import AuthGuard from "./auth/AuthGuard";
import { createBrowserRouter, redirect } from "react-router-dom";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import dataTableRoute from "./views/dataTable/dataTableRoute";
import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
import inboxRoutes from "./views/app/inbox/inboxRoutes";
import chatRoutes from "./views/app/chat/chatRoutes";
import calendarRoutes from "./views/app/calendar/calendarRoutes";
import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
import contactRoutes from "./views/app/contact/contactRoutes";
import Error404 from "./views/sessions/Error";

import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import chartsRoute from "./views/charts/chartsRoute";
import scheduleRoutes from "./views/schedular/scheduleRoutes";
import zoneRoutes from "./views/zone/zoneRoutes";
import zonePincodeRoutes from "./views/zonePincode/zonePincodeRoutes";
import documentTypeRoutes from "./views/document-type/documentTypeRoutes";
import qcReportRoutes from "./views/QC-report/qcReportRoutes";
import companyBankSettingsRoutes from "./views/settings/company-bank-settings/companyBankSettingsRoutes";
import headerSettingsRoutes from "./views/settings/report-settings/headerSettingsRoutes";
import companySettingsRoutes from "./views/settings/company-settings/companySettingsRoutes";
import signatoryRoutes from "./views/signatory/signatoryRoutes";
import reportTemplateFieldsRoutes from "./views/reportTemplateFields/reportTemplateFieldsRoutes";
import lightingRoutes from "./views/lighting/lightingRoutes";
import subReportTypeRoutes from "./views/sub-report-type/subReportTypeRoutes";


const routes = createBrowserRouter([
  {
    element: <AuthGuard />,
    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...dataTableRoute,
      ...invoiceRoutes,
      ...inboxRoutes,
      ...calendarRoutes,
      ...taskManagerRoutes,
      ...contactRoutes,
      ...chatRoutes,
      ...scheduleRoutes,
      ...zoneRoutes,
      ...zonePincodeRoutes,
      ...documentTypeRoutes,
      ...qcReportRoutes,
      ...companyBankSettingsRoutes,
      ...headerSettingsRoutes,
      ...companySettingsRoutes,
      ...signatoryRoutes,
      ...reportTemplateFieldsRoutes,
      ...lightingRoutes,
      ...subReportTypeRoutes,
    ],
  },
  ...sessionsRoutes,
  {
    path: "/",
    exact: true,
    loader: () => redirect("/dashboard"),
  },
  {
    path: "*",
    element: <Error404 />,
  },
]);

export default routes;
