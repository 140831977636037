const initialState = {
    departmentList: []  
  }
  
  const DepartmentReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DEPARTMENT_SUCCESS": {             
        return {
          ...state,
          getDepartmentSuccess: true,
          getDepartmentList: action.payload.data,
        };
      }
      case "GET_DEPARTMENT_FAILURE": {
        return {
          ...state,
          getDepartmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DEPARTMENT": {
        return {
          ...state,
          getDepartmentSuccess: false,
          getDepartmentFailure: false,
          getDepartmentList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_DEPARTMENT_SUCCESS": {             
        return {
          ...state,
          createDepartmentSuccess: true,
          createDepartmentData: action.payload.data,
        };
      }
      case "CREATE_DEPARTMENT_FAILURE": {
        return {
          ...state,
          createDepartmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_DEPARTMENT": {
        return {
          ...state,
          createDepartmentSuccess: false,
          createDepartmentFailure: false,
          createDepartmentData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_DEPARTMENT_SUCCESS": {             
        return {
          ...state,
          updateDepartmentSuccess: true,
          updateDepartmentData: action.payload.data,
        };
      }
      case "UPDATE_DEPARTMENT_FAILURE": {
        return {
          ...state,
          updateDepartmentFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_DEPARTMENT": {
        return {
          ...state,
          updateDepartmentSuccess: false,
          updateDepartmentFailure: false,
          updateDepartmentData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DepartmentReducer
  
  