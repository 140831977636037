import { lazy } from "react";

const AccomodationList = lazy(() => import("./accomodationList"));

const accomodationRoutes = [
  {
    path: "/accomodation",
    element: <AccomodationList />,
  }
];

export default accomodationRoutes;
