import { lazy } from "react";

const RoleList = lazy(() => import("./roleList"));

const roleRoutes = [
  {
    path: "/role",
    element: <RoleList />,
  }
];

export default roleRoutes;
