const initialState = {
    orientationList: []  
  }
  
  const OrientationReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_ORIENTATION_SUCCESS": {             
        return {
          ...state,
          getOrientationSuccess: true,
          getOrientationList: action.payload.data,
        };
      }
      case "GET_ORIENTATION_FAILURE": {
        return {
          ...state,
          getOrientationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_ORIENTATION": {
        return {
          ...state,
          getOrientationSuccess: false,
          getOrientationFailure: false,
          getOrientationList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_ORIENTATION_SUCCESS": {             
        return {
          ...state,
          createOrientationSuccess: true,
          createOrientationData: action.payload.data,
        };
      }
      case "CREATE_ORIENTATION_FAILURE": {
        return {
          ...state,
          createOrientationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_ORIENTATION": {
        return {
          ...state,
          createOrientationSuccess: false,
          createOrientationFailure: false,
          createOrientationData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_ORIENTATION_SUCCESS": {             
        return {
          ...state,
          updateOrientationSuccess: true,
          updateOrientationData: action.payload.data,
        };
      }
      case "UPDATE_ORIENTATION_FAILURE": {
        return {
          ...state,
          updateOrientationFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_ORIENTATION": {
        return {
          ...state,
          updateOrientationSuccess: false,
          updateOrientationFailure: false,
          updateOrientationData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default OrientationReducer
  
  