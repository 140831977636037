import { lazy } from "react";

const FloorList = lazy(() => import("./floorList"));

const floorRoutes = [
  {
    path: "/floor",
    element: <FloorList />,
  }
];

export default floorRoutes;
