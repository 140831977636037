const initialState = {
    airconList: []  
  }
  
  const AirconReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_AIRCON_SUCCESS": {             
        return {
          ...state,
          getAirconSuccess: true,
          getAirconList: action.payload.data,
        };
      }
      case "GET_AIRCON_FAILURE": {
        return {
          ...state,
          getAirconFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_AIRCON": {
        return {
          ...state,
          getAirconSuccess: false,
          getAirconFailure: false,
          getAirconList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_AIRCON_SUCCESS": {             
        return {
          ...state,
          createAirconSuccess: true,
          createAirconData: action.payload.data,
        };
      }
      case "CREATE_AIRCON_FAILURE": {
        return {
          ...state,
          createAirconFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_AIRCON": {
        return {
          ...state,
          createAirconSuccess: false,
          createAirconFailure: false,
          createAirconData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_AIRCON_SUCCESS": {             
        return {
          ...state,
          updateAirconSuccess: true,
          updateAirconData: action.payload.data,
        };
      }
      case "UPDATE_AIRCON_FAILURE": {
        return {
          ...state,
          updateAirconFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_AIRCON": {
        return {
          ...state,
          updateAirconSuccess: false,
          updateAirconFailure: false,
          updateAirconData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default AirconReducer
  
  