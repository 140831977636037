import { lazy } from "react";

const UninspectedCaseList = lazy(() => import("./uninspectedCaseList"));

const unInspectedRoutes = [
  {
    path: "/uninspected-case",
    element: <UninspectedCaseList />,
  }
];

export default unInspectedRoutes;
