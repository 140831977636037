const initialState = {
    doorsList: []  
  }
  
  const DoorsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_DOORS_SUCCESS": {             
        return {
          ...state,
          getDoorsSuccess: true,
          getDoorsList: action.payload.data,
        };
      }
      case "GET_DOORS_FAILURE": {
        return {
          ...state,
          getDoorsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_DOORS": {
        return {
          ...state,
          getDoorsSuccess: false,
          getDoorsFailure: false,
          getDoorsList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_DOORS_SUCCESS": {             
        return {
          ...state,
          createDoorsSuccess: true,
          createDoorsData: action.payload.data,
        };
      }
      case "CREATE_DOORS_FAILURE": {
        return {
          ...state,
          createDoorsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_DOORS": {
        return {
          ...state,
          createDoorsSuccess: false,
          createDoorsFailure: false,
          createDoorsData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_DOORS_SUCCESS": {             
        return {
          ...state,
          updateDoorsSuccess: true,
          updateDoorsData: action.payload.data,
        };
      }
      case "UPDATE_DOORS_FAILURE": {
        return {
          ...state,
          updateDoorsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_DOORS": {
        return {
          ...state,
          updateDoorsSuccess: false,
          updateDoorsFailure: false,
          updateDoorsData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default DoorsReducer
  
  