const initialState = {
    serviceFacilitiesList: []  
  }
  
  const ServiceFacilitiesReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_SERVICE_FACILITIES_SUCCESS": {             
        return {
          ...state,
          getServiceFacilitiesSuccess: true,
          getServiceFacilitiesList: action.payload.data,
        };
      }
      case "GET_SERVICE_FACILITIES_FAILURE": {
        return {
          ...state,
          getServiceFacilitiesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_SERVICE_FACILITIES": {
        return {
          ...state,
          getServiceFacilitiesSuccess: false,
          getServiceFacilitiesFailure: false,
          getServiceFacilitiesList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_SERVICE_FACILITIES_SUCCESS": {             
        return {
          ...state,
          createServiceFacilitiesSuccess: true,
          createServiceFacilitiesData: action.payload.data,
        };
      }
      case "CREATE_SERVICE_FACILITIES_FAILURE": {
        return {
          ...state,
          createServiceFacilitiesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_SERVICE_FACILITIES": {
        return {
          ...state,
          createServiceFacilitiesSuccess: false,
          createServiceFacilitiesFailure: false,
          createServiceFacilitiesData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_SERVICE_FACILITIES_SUCCESS": {             
        return {
          ...state,
          updateServiceFacilitiesSuccess: true,
          updateServiceFacilitiesData: action.payload.data,
        };
      }
      case "UPDATE_SERVICE_FACILITIES_FAILURE": {
        return {
          ...state,
          updateServiceFacilitiesFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_SERVICE_FACILITIES": {
        return {
          ...state,
          updateServiceFacilitiesSuccess: false,
          updateServiceFacilitiesFailure: false,
          updateServiceFacilitiesData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ServiceFacilitiesReducer
  
  