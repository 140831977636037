const initialState = {
    reportTypeList: []  
  }
  
  const ReportTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_REPORT_TYPE_SUCCESS": {             
        return {
          ...state,
          getReportTypeSuccess: true,
          getReportTypeList: action.payload.data,
        };
      }
      case "GET_REPORT_TYPE_FAILURE": {
        return {
          ...state,
          getReportTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REPORT_TYPE": {
        return {
          ...state,
          getReportTypeSuccess: false,
          getReportTypeFailure: false,
          getReportTypeList: [],
          errorMessage: false
        };
      }

      case "GET_REPORT_TYPE_DATA_SUCCESS": {             
        return {
          ...state,
          getReportTypeDataSuccess: true,
          getReportTypeDataList: action.payload.data,
        };
      }
      case "GET_REPORT_TYPE_DATA_FAILURE": {
        return {
          ...state,
          getReportTypeDataFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_REPORT_TYPE_DATA": {
        return {
          ...state,
          getReportTypeDataSuccess: false,
          getReportTypeDataFailure: false,
          getReportTypeDataList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_REPORT_TYPE_SUCCESS": {             
        return {
          ...state,
          createReportTypeSuccess: true,
          createReportTypeData: action.payload.data,
        };
      }
      case "CREATE_REPORT_TYPE_FAILURE": {
        return {
          ...state,
          createReportTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_REPORT_TYPE": {
        return {
          ...state,
          createReportTypeSuccess: false,
          createReportTypeFailure: false,
          createReportTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_REPORT_TYPE_SUCCESS": {             
        return {
          ...state,
          updateReportTypeSuccess: true,
          updateReportTypeData: action.payload.data,
        };
      }
      case "UPDATE_REPORT_TYPE_FAILURE": {
        return {
          ...state,
          updateReportTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_REPORT_TYPE": {
        return {
          ...state,
          updateReportTypeSuccess: false,
          updateReportTypeFailure: false,
          updateReportTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default ReportTypeReducer
  
  