const initialState = {
    timeSlotDetailsList: []  
  }
  
  const TimeSlotDetailsReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_TIME_SLOT_DETAILS_SUCCESS": {             
        return {
          ...state,
          getTimeSlotDetailsSuccess: true,
          getTimeSlotDetailsList: action.payload.data,
        };
      }
      case "GET_TIME_SLOT_DETAILS_FAILURE": {
        return {
          ...state,
          getTimeSlotDetailsFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_TIME_SLOT_DETAILS": {
        return {
          ...state,
          getTimeSlotDetailsSuccess: false,
          getTimeSlotDetailsFailure: false,
          getTimeSlotDetailsList: [],
          errorMessage: false
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default TimeSlotDetailsReducer
  
  